<template>
  <div class="pin-page h-100 text-center">
    <b-container
      fluid
      class="h-100"
    />
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";

export default {
  name: "AppQuickAuth",
  data () {
    return {
      code: ""
    };
  },
  created: async function () {
    if (this.$route.query.baseUrl && this.$route.query.token) {
      const connection = {
        "token": this.$route.query.token,
        "url": this.$route.query.baseUrl
      };
      await this.$store.dispatch('config/setAuthConfig', connection)
      await this.$store.dispatch('config/fetchTerminal')
      await this.$router.push({ name: "Synchronize" });
    } else {
      console.error("Error =(");
    }
  },
  methods: {
    changeLocale (locale) {
      i18n.locale = locale;
    }
  }
};
</script>
