<template>
  <pos-layout>
    <template #navbar>
      <span>&nbsp;</span>
    </template>

    <b-container
      fluid
      class="h-100 px-0"
    >
      <div class="h-100 orders">
        <swiper
          ref="subordersSwiper"
          :options="swiperOption"
          class="subordersSwiper row no-gutters h-100"
        >
          <swiper-slide
            v-for="(order, orderIndex) in tableOrders"
            :id="'orderItems'+orderIndex"
            :data-id="orderIndex"
            :key="orderIndex"
            class="col-12 col-md-6 col-lg-4 h-100 p-0"
          >
            <div class="orders-sidebar">
              <div class="orders-head">
                <div class="orders-top">
                  <div class="d-flex align-items-center">
                    <div class="orders-top-num">
                      <span>
                        {{ $t("orderscreen.order-no") }}
                      </span>
                      <strong>
                        # {{ (order.id || order.local_id) }}
                      </strong>
                    </div>

                    <div class="orders-top-btn ml-auto d-flex align-items-center">
                      <b-button
                        :to="{ name: 'Order', params: { id: (order.id || order.local_id), tableID: tableID } }"
                        variant="dark"
                      >
                        {{ $t("amountandpaymentmethod.edit-order") }}
                      </b-button>
                    </div>
                  </div>
                </div>

                <div class="orders-add">
                  <a
                    href="#"
                    class="btn-link"
                    style="opacity: 0"
                  >
                    <svg class="icon icon-icon-add">
                      <use xlink:href="#icon-icon-add" />
                    </svg>

                    <span>
                      {{ $t("orderscreen.add-customer") }}
                    </span>
                  </a>

                  <a
                    v-if="!doesItHaveSelectedItem(order.id) && activeOrderIndex != orderIndex && activeOrderIndex != null"
                    class="btn btn-warning btn-link-behav"
                    @click="moveItem(orderIndex)"
                  >
                    {{ $t("orderscreen.move") }}
                  </a>
                </div>
              </div>

              <div class="orders-sidebar-body">
                <div
                  class="orders-list"
                  role="tablist"
                >
                  <b-card
                    v-for="(item, index) in filterOutVoidItems(order.items)"
                    :key="index"
                    :class="{'selected': index == activeItemIndex && orderIndex == activeOrderIndex }"
                    no-body
                    @click="toggle({item, index},{order, orderIndex})"
                  >
                    <b-card-header
                      header-tag="header"
                      class="orders-item-head p-0"
                      role="tab"
                    >
                      <b-button
                        block
                        href="#"
                      >
                        <div class="d-flex">
                          <div class="btn-num">
                            {{ item.quantity }}
                          </div>

                          <div class="btn-body">
                            <div class="d-flex align-items-center justify-content-between top">
                              <div class="text-left">
                                {{ item.name }}
                              </div>
                              <div class="text-right">
                                {{ currency }} {{ (getItemGrandTotal(item)).toFixed(2) }}
                              </div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between sub">
                              <div class="text-left">
                                {{ $t("orderscreen.discount") }}
                              </div>
                              <div class="text-right">
                                {{ currency }} {{ item.discount.toFixed(2) }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-button>
                    </b-card-header>
                    <div
                      v-if="item.notes && item.notes.length"
                      class="card-notes"
                    >
                      {{ item.notes }}
                    </div>
                  </b-card>
                </div>
              </div>

              <div class="orders-bottom">
                <div class="orders-bottom-item">
                  <span>
                    {{ $t("ordertotal.sub-total") }}
                  </span>
                  <span>{{ currency }} {{ order.subtotal ? order.subtotal.toFixed(2) : "0.00" }}</span>
                </div>

                <div class="orders-bottom-item">
                  <span class="text-primary">
                    {{ $t("ordertotal.tax") }}
                  </span>
                  <span>{{ currency }} {{ order.tax_total.toFixed(2) }}</span>
                </div>
                <div class="orders-bottom-item">
                  <span class="text-left">
                    {{ $t("ordertotal.total") }}
                  </span>
                  <span class="text-right">
                    {{ currency }} {{ order.grand_total ? order.grand_total.toFixed(2) : "0.00" }}
                  </span>
                </div>
                <div
                  v-if="isPaymentTypesAvailable"
                  class="orders-bottom-options"
                >
                  <div
                    :class="{'disabled': order.subtotal<=0 || !isPaymentTypesAvailable }"
                    class="pay"
                    @click="goToCheckout(order)"
                  >
                    <div class="d-flex align-items-center justify-content-between">
                      <span class="text-left">
                        {{ $t("orderscreen.pay") }}
                      </span>
                      <span class="text-right">
                        {{ currency }} {{ order.balance_remaining ? order.balance_remaining.toFixed(2) : "0.00" }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>

        <div class="total">
          <div
            v-for="(order, orderIndex) in tableOrders"
            :id="'orderItems'+orderIndex"
            :data-id="orderIndex"
            :key="orderIndex"
            class="total-item"
          >
            <div class="total-head">
              <strong># {{ order.id || order.local_id }}</strong>
            </div>

            <div class="total-body">
              <ul class="list-unstyled">
                <li>
                  {{ currency }} {{ order.subtotal.toFixed(2) }}
                </li>
                <li>
                  <strong class="text-danger">
                    {{ currency }} {{ order.grand_total.toFixed(2) }}
                  </strong>
                </li>
              </ul>
            </div>
          </div>

          <!--          <router-link tag="div" class="total-add" :to="{ name: 'Order', params: { tableID: tableID, id: 'new'  } }">-->
          <div
            class="total-add"
            @click="newOrderAtTable(tableID)"
          >
            <!--            <router-link tag="div" class="pay" :class="{'disabled': order.subtotal<=0 }" :to="{ name: 'OrderCheckout', params: { tableID: tableID, orderID: order.id  } }">-->
            <div class="total-add-head">
              {{ $t("orderscreen.add-order") }}
            </div>
            <div class="total-add-body">
              +
            </div>
          </div>
          <!--</router-link>-->
        </div>
      </div>
      <scale-loader
        :loading="isLoad"
        :color="'#007bff'"
      />
    </b-container>
  </pos-layout>
</template>

<script>
import PosLayout from "../layouts/PosLayout";
import orderMixin from "../mixins/order/orderMixin";
import { TAX_OPTION } from "@/helpers/store/tax_options";
import ScaleLoader from "vue-spinner/src/ScaleLoader";
import { mapGetters, mapActions } from "vuex";
import cdOrderMixin from "@/mixins/cdOrderMixin"
import * as Sentry from "@sentry/vue";

export default {
  name: "TableOrders",
  components: {
    PosLayout,
    ScaleLoader
  },
  mixins: [orderMixin, cdOrderMixin],
  data () {
    return {
      isLoad: false,
      swiperOption: {
        slidesPerView: "auto",
        spaceBetween: 0,
        freeMode: true,
        touchRatio: 1,
        touchAngle: 90,
        shortSwipes: false,
        threshold: 30,
        slideToClickedSlide: false,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        breakpoints: {
          992: {
            slidesPerView: 2,
            spaceBetween: 0
            // simulateTouch: false
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 0
            // simulateTouch: false
          }
        }
      },
      tax: "$",
      tableOrders: [],
      selectedItem: {
        id: 35279,
        orderID: null
      },
      activeItemIndex: null,
      activeOrderIndex: null,
      tableID: 0,
      table: null,
      is_tax_inclusive: false
    };
  },
  computed: {
    ...mapGetters({
      terminal: "config/terminal",
      store: "config/store",
      isKioskMode: "config/isKioskMode",
      currency: "config/currency",
      employee: "employee"
    })
  },
  async created() {
    await this.fetchTaxFromDb()
    await  this.fetchTablesFromDb()
    this.isLoad = true;
    this.tax = this.$store.getters["config/tax"].rate;
    this.is_tax_inclusive = this.$store.getters["config/store"].tax_option == TAX_OPTION.TAX_INCLUSIVE;
    try {
      this.tableID = this.$route.params.id;
      this.init();
      this.isLoad = false;
    } catch (e) {
      Sentry.captureException(e);
      this.isLoad = false;
      throw Error(`Error at TableOrders init step`, e);
    }
    this.isLoad = false;
  },
  methods: {
    ...mapActions({
      fetchTaxFromDb: 'config/fetchTaxFromDb',
      fetchTablesFromDb: 'config/fetchTablesFromDb',
      fetchOrdersFromDb:'orders/fetchOrdersFromDb'
    }),
    init: function () {
      this.table = this.$store.getters.getTableWithOrders(parseInt(this.$route.params.id));
      this.tableOrders = this.table.tableOrders;

    },
    goToCheckout: async function (order) {
      if (!this.isPaymentTypesAvailable) return;
      this.sendOrderToChannel(order)
      await this.$router.push({
        name: "OrderCheckout",
        params: { id: (order.id || order.local_id) }
      });
    },
    doesItHaveSelectedItem: function (orderID) {
      return this.tableOrders.find(order => order.id === orderID)
        .items.some(orderItem => orderItem.id === this.selectedItem["itemID"]);
    },
    toggle: function (itemData, orderData) {
      if (this.activeItemIndex === itemData.index && this.activeOrderIndex === orderData.orderIndex)
        return this.unselectItem();
      this.activeOrderIndex = orderData.orderIndex;
      this.activeItemIndex = itemData.index;
      this.selectedItem = {
        itemID: itemData.item.id,
        itemData,
        orderData
      };
    },
    unselectItem () {
      this.activeOrderIndex = null;
      this.activeItemIndex = null;
    },
    recalculateSums: function (orderIndex) {
      // todo move to mixing or external helper, or store
      const self = this;
      this.tableOrders[orderIndex].subtotal = 0;
      this.tableOrders[orderIndex].tax_total = 0;
      this.tableOrders[orderIndex].items.filter(item => item.void_status !== 1).forEach(function (item) {
        let itemModsSum = 0;
        if (item.modifiers) {
          for (const modId in item.modifiers) {
            if (!item.modifiers[modId].void_status) {
              itemModsSum += item.modifiers[modId].price;
            }
          }
        }
        self.tableOrders[orderIndex].subtotal = Math.max(0,
          self.tableOrders[orderIndex].subtotal + (item.quantity * (item.price_base + itemModsSum - item.discount)));
        self.tableOrders[orderIndex].discount_total = parseFloat((self.tableOrders[orderIndex].discount_total + (item.discount * item.quantity)).toFixed(2));
        if (item.tax) {
          self.tableOrders[orderIndex].tax_total += item.tax * item.quantity;
        }
      });
      this.tableOrders[orderIndex].grand_total = parseFloat((this.tableOrders[orderIndex].subtotal
        + this.tableOrders[orderIndex].tax_total).toFixed(2));

      const order = self.tableOrders[orderIndex];
      let paymentEntriesSum = 0;
      order.payments && order.payments.map(payment => paymentEntriesSum += payment.amount);
      this.tableOrders[orderIndex].balance_remaining = order.subtotal + (this.is_tax_inclusive ? 0
        : order.tax_total - paymentEntriesSum);
    },
    moveItem: async function (receiverOrderIndex) {
      const senderOrderIndex = this.selectedItem.orderData.orderIndex;
      this.activeItemIndex = this.tableOrders[receiverOrderIndex].items.length;
      this.tableOrders[senderOrderIndex].items.splice(this.selectedItem.itemData.index, 1);
      this.tableOrders[receiverOrderIndex].items.push(this.selectedItem.itemData.item);

      // Recalculate
      this.recalculateSums(receiverOrderIndex);
      this.recalculateSums(senderOrderIndex);
      this.activeOrderIndex = receiverOrderIndex;
      this.selectedItem.orderData.orderIndex = receiverOrderIndex;
      this.selectedItem.itemData.index = this.activeItemIndex;
      this.$store.commit("orders/saveOrder", this.tableOrders[receiverOrderIndex]);
      this.$store.commit("orders/saveOrder", this.tableOrders[senderOrderIndex]);
      this.$store.dispatch("sync");
      this.unselectItem();
    },
    updateLocalId: async function (msgObj) {
      const index = this.tableOrders.findIndex(order => order.id === msgObj.localID);
      if (index != undefined) {
        this.tableOrders[index].id = msgObj.remoteID;
      }
    },
    newOrderAtTable: async function (tableID) {
      const newOrder = {
        local_id: `${this.terminal.id}_${Date.now()}`,
        open_date: new Date().toISOString(),
        emp_open: this.employee ? this.employee.id : null,
        subtotal: 0,
        updated_at: new Date().toISOString(),
        items: [],
        table: tableID,
        tax_total: 0,
        status: 1,
        discount_total: 0,
        order_discount: 0,
        discount_orders: 0,
        grand_total: 0,
        number: "0",
        payments: [],
        related_terminal: this.terminal.id,
        locked_by: this.terminal ? this.terminal.id : null,
        dining_option_type: this.store.default_dining_option,
        cash_discount:false
      };
      this.$store.commit("orders/saveOrder", newOrder);
      await this.$store.dispatch("sync");
      const table = this.$store.getters.getTableWithOrders(tableID);
      this.tableOrders = table.tableOrders;
    },
    filterOutVoidItems: function (items) {
      return items.filter(function (item) {
        return item.void_status !== 1;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.swiper-container {
  // overflow: visible;
  padding-right: 120px;

  .orders-sidebar:after {
    content: none;
  }

  .swiper-slide {
    margin-right: 2px;
  }
}

.swiper-pagination {
  display: none;
}

</style>
