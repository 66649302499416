import * as qz from 'qz-tray';
import store from "../../store/store";

export async function sendToScales(saveResult){
  const terminal = store.getters['config/terminal'];
  const connectOptions = terminal.printer_server_ip?{...{host: terminal.printer_server_ip}}:{};

  if(!qz.websocket.isActive()){
    await qz.websocket.connect(connectOptions).then(function(){
    }).catch(function(e){
      console.error('Can not connect to qz tray', e);
      throw e;
    });
  }

  const port = terminal.scale?terminal.scale:'COM1';

  const properties = {
    start: '',
    end: '',
    width: null,
    baudRate: 9600,
    dataBits: 8,
    stopBits: 1,
    parity: 'NONE',
    flowControl: 'NONE',
    encoding: 'ASCII'
  };

  qz.serial.setSerialCallbacks(function(streamEvent){
    if(streamEvent.type !== 'ERROR'){
      const rawWeight = streamEvent.output.toString();
      saveResult(rawWeight);
    } else{
      console.error(streamEvent.exception);
    }
     qz.serial.closePort(port)
  });

  await qz.serial.openPort(port, properties).then(function(){
    console.log(`${port} opened`);

  }).catch(function(err){
    console.error(err);
  });

  const serialData = {
    data: 'W\r',
    type: 'PLAIN'
  };
  await qz.serial.sendData(port, serialData, properties);
}
