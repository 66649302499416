<template>
  <div>
    <b-modal
      id="select-customer-modal"
      no-close-on-backdrop
      hide-footer
      body-class="p-0 customer-modal-wrapper"
      style="height: 90vh"
      no-fade
    >
      <template
        slot="modal-header"
        slot-scope="{ close }"
      >
        <b-button
          variant="outline-light"
          @click="close()"
        >
          {{ $t("customerlistpopup.cancel") }}
        </b-button>
        <h5>
          {{ $t("customerlistpopup.select-customer") }}
        </h5>
        <b-button
          variant="primary"
          @click="showAddCustomerModal"
        >
          {{ $t("customerlistpopup.add") }}
        </b-button>
      </template>
      <div class="search">
        <label 
          class="search__icon-label" 
          for="search-customer-input"
        >
          <svg class="icon icon-icon-search">
            <use xlink:href="#icon-icon-search" />
          </svg>
        </label>
        <b-input
          id="search-customer-input"
          ref="searchField"
          v-model="search"
          :placeholder="$t('searchbar.search-item')"
          class="search__customer-input"
          autocomplete="off"
        />
      </div>
      <div class="content">
        <div
          v-for="customer in filteredCustomers"
          :key="customer.id"
          class="orders-bottom-item"
        >
          <div class="text-left">
            <span>
              {{ customerFullName(customer) }}
            </span>
          </div>
          <div class="text-right">
            <span class="px-2">
              {{ customer.phone }}
            </span>
            <b-button
              variant="outline-light"
              @click="showViewCustomerModal(customer)"
            >
              {{ $t("customerlistpopup.view-customer") }}
            </b-button>
            <b-button
              v-if="selectedCustomer && customer.id === selectedCustomer.id"
              variant="danger"
              @click="removeCustomer"
            >
              {{ $t("customerlistpopup.remove-customer") }}
            </b-button>
            <span v-else>
              <b-button
           
                variant="outline-light"
                @click="selectCustomer(customer)"
              >
                {{ $t("customerlistpopup.apply-customer") }}
              </b-button>
              <b-button
                variant="outline-light"
                @click="openAddNewAddressModal(customer)"
              >
                Update Address

                <!-- {{ $t("customerlistpopup.apply-customer") }} -->
              </b-button>
            </span>
          </div>
        </div>
        <div
          v-if="search.length !== 0"
          class="orders-bottom-item orders-bottom-item__add-new"
          @click="showAddCustomerModal"
        >
          <span>
            Add New Customer
          </span>
        </div>
      </div>
      <CustomerModal
        :view-customer="viewCustomer"
        :is-selected="isSelectedViewCustomer"
        get-customers="getCustomers"
        @select-customer-after-add="selectCustomer"
        @select-customer="selectCustomer"
        @close-modal="clearViewCustomer"
      />
    </b-modal>
    <AddNewAddressModal
      :open="showUpdateAddAddressModal"
      :customer="updateAddressCustomer"
      @closeModal="closeAddNewAddressModal"
    />
  </div>
</template>

<script>
import viewCustomerModalMixin from "../mixins/modals/viewCustomerModalMixin";
// import AddNewAddressModal from "./AddNewAddressModal.vue";
export default {
  name: "SelectCustomerModal",
  components: {
    CustomerModal: () => import("./CustomerModal"),
    AddNewAddressModal : ()=>import('../modals/Address/AddNewAddressModal.vue')
},
  mixins: [viewCustomerModalMixin],
  props: ["orderCustomer"],
  data () {
    return {
      selectedCustomer: null,
      search: "",
      showUpdateAddAddressModal:false,
      updateAddressCustomer:{}
    };
  },
  computed: {
    customers () {
      return this.$store.getters["customers/getAllCustomers"];
    },
    isSelectedViewCustomer () {
      if (this.selectedCustomer && this.viewCustomer !== null) {
        return this.viewCustomer.local_id === this.selectedCustomer.local_id;
      }
      return false;
    },
    filteredCustomers () {
      const self = this;
      const result = this.search.length !== 0 ? this.customers.filter(customer =>
        customer.first_name && customer.first_name.toLowerCase().includes(self.search.toLowerCase())
        || customer.last_name && customer.last_name.toLowerCase().includes(self.search.toLowerCase())
      ) : this.customers;
      if (!this.selectedCustomer) return result;
      return result.sort((a) => a.id === self.selectedCustomer.id ? -1 : 1);
    }
  },
  watch: {
    orderCustomer (customer) {
      return this.selectedCustomer = customer;
    }
  },
  methods: {
    // async getCustomers () {
    //   try {
    //     this.customers = this.$store.getters['customers/list']
    //   } catch (e) {
    //     this.$log.warn(e);
    //   }
    // },
    // receiveCustomerMessage (event) {
    //   const message = event.data;
    //   if (message.action === "sync" && message.type === "customerSynced") {
    //     const self = this;
    //     this.customers.map(customer => {
    //       if (customer.id === message.localID)
    //         customer.id = message.remoteID;
    //
    //       if (self.selectedCustomer.id === message.localID)
    //         self.selectedCustomer.id = message.remoteID;
    //     });
    //   }
    // },
    showAddCustomerModal () {
      this.$bvModal.show("customer-modal");
    },
    customerFullName (customer) {
      return `${customer.first_name}${customer.last_name ? (" " + customer.last_name) : ""}`;
    },
    selectCustomer (customer) {
      this.clearViewCustomer();
      this.$emit("select-customer", customer);
      this.$bvModal.hide("select-customer-modal");
    },
    removeCustomer (customer) {
      this.$emit("remove-customer", customer);
    },
    openAddNewAddressModal(customer=null){
      if(customer)
      {
      
        this.updateAddressCustomer = customer
        this.showUpdateAddAddressModal = true
      }

    },
    closeAddNewAddressModal(){
      this.showUpdateAddAddressModal = false
    }
    
  }
};
</script>

<style scoped lang="scss">
.customer-modal-wrapper {
  height: 90vh !important;
}

.search__customer-input {
  background: none;
  border: none;
  padding-left: 40px;
  height: 3.5rem;
}

.search__icon-label {
  position: absolute;
  color: grey;
  left: 15px;
  top: 15px;
  margin: 0;
}

.modal-body {
  padding: 0 !important;
  height: 90vh !important;
}

.modal-dialog {
  height: 90vh !important;
}

.content {
  overflow: auto;
}

.orders-bottom-item {
  background: white;

  &__add-new {
    color: #2196F3;
  }
}
</style>
