<template>
  <div id="c">
    <SimpleNumKeyboard @updated="processNumOutput" />
  </div>
</template>
<script>
  export default {
    name: 'Numpad',
    components: {
      SimpleNumKeyboard: () => import('./SimpleNumKeyboard')
    },
    data () {
      return {
        numString: '',
        numFloat: ''
      };
    },
    methods: {
      sendToParent: function () {
        let valueToReturn;
        if (this.numString === '') {
          valueToReturn = 0;
        } else {
          valueToReturn = (parseFloat(this.numString) / 100);
        }
        this.$emit('updated', valueToReturn);
      },
      processNumOutput: async function (num) {
        this.numString = num;
        this.sendToParent();
      },
      resetNum: function () {
        this.numString = '';
        this.sendToParent();
      }
    }
  };
</script>
<style lang="scss" scoped>
  .pin-page {
    background: linear-gradient(180deg, #1976D2 0%, #2196F3 100%);
    color: #fff;
    min-height: 100vh;

    h6 {
      font-size: 15px;
      line-height: 18px;
    }

    .pin-block {
      padding-top: 15px;
    }
  }

  .navbar {
    padding: 30px 0 30px;
  }

  .navbar-brand {
    padding: 0;
    margin: 0 auto;
    height: 60px;

    img {
      max-height: 100%;
      height: 100%;
    }
  }
</style>
