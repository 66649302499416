<template>
  <pos-layout @orderChannel="sendOrderToChannel(currentOrder)">
    <template #navbar>
      <b-navbar-nav>
        <b-nav-form
          on-submit="return false;"
          class="navbar-search"
          autocomplete="off"
        >
          <label for="navbar-search-control">
            <svg class="icon icon-icon-search">
              <use xlink:href="#icon-icon-search" />
            </svg>
          </label>
          <form @submit.prevent>
            <b-form-input
              id="navbar-search-control"
              ref="searchField"
              :value="search.value"
              :placeholder="$t('searchbar.search-item')"
              autocomplete="off"
              @input="onInputChangeSearch"
              @keyup.enter="findProduct"
              @focus="showSearch"
            />
          </form>

          <b-dropdown
            ref="barcodeDropdown"
            size="lg"
            variant="link"
            toggle-class="text-decoration-none navbar-search__barcode-btn"
            no-caret
          >
            <template v-slot:button-content>
              <svg class="icon icon-icon-code">
                <use xlink:href="#icon-icon-code" />
              </svg>
            </template>
            <b-dropdown-text>
              <NumPad
                :search-value="search.value"
                @confirm-action="searchByBarcode"
                @barcode-input-change="barcodeInputChange"
                @hide-barcode-numpad="hideBarcodeNumpad"
              />
            </b-dropdown-text>
          </b-dropdown>
          <div
            v-if="search.visible"
            class="navbar-search-res show"
          >
            <ul class="list-unstyled">
              <li
                v-for="foundProduct in search.results"
                :key="foundProduct.id"
                @click="addItemToOrder(foundProduct)"
              >
                <div class="d-flex justify-content-between">
                  <div>
                    <span>{{ foundProduct.name }}</span>
                  </div>
                  <div>
                    <span class="float-right text-nowrap">{{ currency }} {{ foundProduct.price.toFixed(2) }}</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </b-nav-form>
        <div
          v-if="search.visible && !$refs.barcodeDropdown.visible"
          class="keyboard-mask"
          @click="hideSearch"
        />
      </b-navbar-nav>
    </template>

    <div class="h-100 orders d-print-none">
      <!-- Print panel For mobile -->
      <div class="print d-md-none d-print-none">
        <b-row class="h-100 no-gutters">
          <b-col
            cols="3"
            class="h-100 d-md-none"
          >
            <b-button
              :to="isSavingProcess?{}:{ name: 'Tables', params: { id: currentOrder.table }}"
              variant="link"
              class="w-100 bg-pink"
            >
              <svg class="icon icon-icon-table">
                <use xlink:href="#icon-icon-table" />
              </svg>

              <span>
                {{ $t("amountandpaymentmethod.print-to-tables-mob") }}
              </span>
            </b-button>
          </b-col>

          <b-col
            cols="3"
            class="h-100"
          >
            <b-button
              :class="{'btn-disabled': itemsAtCurrentOrder.length === 0}"
              variant="link"
              class="w-100"
              @click="printCheck()"
            >
              <svg class="icon icon-icon-print-1">
                <use xlink:href="#icon-icon-print-1" />
              </svg>
              <span>
                {{ $t("amountandpaymentmethod.print-check") }}
              </span>
            </b-button>
          </b-col>
          <b-col
            cols="3"
            class="h-100"
          >
            <div
              v-if="isKitchenPrintProcessing"
              class="text-center spiner-overbutton"
            >
              <b-spinner
                variant="primary"
                label="Text Centered"
              />
            </div>
            <b-button
              :class="{'btn-disabled': !kitchenUpdatesAvailable || isKitchenPrintProcessing}"
              variant="link"
              class="w-100"
              @click="printKitchenCheck()"
            >
              <svg class="icon icon-icon-print-2">
                <use xlink:href="#icon-icon-print-2" />
              </svg>

              <span>
                {{ $t("amountandpaymentmethod.kitchen-print") }}
              </span>
            </b-button>
          </b-col>
          <b-col
            cols="3"
            class="h-100 d-none d-md-block"
          >
            <b-button
              variant="link"
              class="w-100"
            >
              <svg class="icon icon-icon-print-3">
                <use xlink:href="#icon-icon-print-3" />
              </svg>

              <span>
                {{ $t("amountandpaymentmethod.open-drawer") }}
              </span>
            </b-button>
          </b-col>
          <b-col
            cols="3"
            class="h-100 d-none d-md-block"
          >
            <b-button
              variant="link"
              class="w-100"
            >
              <svg class="icon icon-icon-more-options">
                <use xlink:href="#icon-icon-more-options" />
              </svg>

              <span>
                {{ $t("amountandpaymentmethod.more-options") }}
              </span>
            </b-button>
          </b-col>

          <b-col
            v-if="!sidebarMob"
            cols="3"
            class="h-100 d-md-none"
          >
            <b-button
              variant="link"
              class="w-100 bg-primary"
              @click="sidebarMob = !sidebarMob"
            >
              <svg class="icon icon-icon-mob-add">
                <use xlink:href="#icon-icon-mob-add" />
              </svg>

              <span>
                {{ $t("amountandpaymentmethod.print-add-items-mob") }}
              </span>
            </b-button>
          </b-col>

          <b-col
            v-if="sidebarMob"
            cols="3"
            class="h-100 d-md-none"
          >
            <b-button
              variant="link"
              class="w-100 bg-info"
              @click="sidebarMob = !sidebarMob"
            >
              <svg class="icon icon-icon-mob-view">
                <use xlink:href="#icon-icon-mob-view" />
              </svg>

              <span>
                {{ $t("amountandpaymentmethod.print-view-order-mob") }}
              </span>

              <span class="badge">
                <transition name="bounce">
                  <i :key="itemsAtCurrentOrder">{{ itemsAtCurrentOrderLength }} </i>
                </transition>
              </span>
            </b-button>
          </b-col>
        </b-row>
      </div>

      <b-row class="h-100 no-gutters d-print-none">
        <b-col
          :class="{hide: sidebarMob}"
          col
          cols="12"
          md="5"
          lg="4"
          class="h-100 orders-sidebar-mob"
        >
          <div
            :class="{ 'orders-sidebar-kiosk': isKioskMode }"
            class="orders-sidebar"
          >
            <div
              :aria-pressed="modifiersShow ? 'OrderCheckout' : 'false'"
              class="modifiers-sidebar-backdrop"
              @click="modifiersSave"
            />

            <OrderPanel
              :current-order="currentOrder"
              :order-panel-order-dining-option="orderDiningOption"
              :default-dining-option="defaultDiningOption"
              :payments-cash-entries="paymentsCashEntries"
              :current-order-sum="currentOrderSum"
              :order-panel-table="table"
              :is-display-payment-details="isDisplayPaymentDetails"
              :is-load="isLoad"
              :all-discounts="allDiscounts"
              :is-kitchen-print-processing="isKitchenPrintProcessing"
              :discount="discount"
              :order-items="orderItems"
              @showSelectCustomerModal="showSelectCustomerModal"
              @showDiningOptionsModal="showDiningOptionsModal"
              @showPriceAdjustModal="showPriceAdjustModal"
              @showNotesModal="showNotesModal"
              @displayPaymentDetails="displayPaymentDetails"
              @goToCheckout="goToCheckout"
              @holdOrder="holdOrder"
              @saveDiscountToOrder="saveDiscountToOrder"
              @changeDiscount="changeDiscount"
              @modifiersOpen="modifiersOpen"
            />
          </div>
        </b-col>

        <b-col
          col
          cols="12"
          md="7"
          lg="8"
          class="h-100"
        >
          <div class="products-wrap h-100 ">
            <div class="category">
              <swiper :options="swiperOption">
                <swiper-slide
                  v-for="category in categories"
                  :data-id="category.id"
                  :key="category.id"
                  :style="{ background: transformColor[category.color.toLowerCase()] ? transformColor[category.color.toLowerCase()] : category.color}"
                  :class="{ active: category.id === currentCategory }"
                  class="category-slide"
                  @click.native="selectCategory(category.id)"
                >
                  <img
                    v-if="category.image"
                    :src="category.image"
                    :alt="category.name"
                    class="img-responsive"
                  >
                  <div
                    v-if="category.image"
                    class="category-overlay"
                  />

                  <span class="category-name">
                    {{ category.name }}
                  </span>
                </swiper-slide>
              </swiper>
            </div>

            <div
              id="productWrapper"
              class="products"
            >
              <div
                v-for="product in paginatedProducts"
                :key="product.id"
                :class="'products-item-'+product.id"
                :style="{ background: transformColor[product.color.toLowerCase()] ? transformColor[product.color.toLowerCase()] : product.color}"
                class="products-item"
                @click="addItemToOrder(product)"
              >
                <b-button
                  :class="{ 'd-none': !isKioskMode || !product.image || !product.description }"
                  class="products-info"
                  variant="link"
                  @click.stop.prevent="$bvModal.show('modal-show-info-'+product.id)"
                />

                <b-modal
                  :id="'modal-show-info-'+product.id"
                  hide-footer
                  modal-class="info-popup"
                >
                  <div
                    :style="{ background: transformColor[product.color.toLowerCase()] ? transformColor[product.color.toLowerCase()] : product.color}"
                    class="products-info-thumb"
                  >
                    <img
                      v-if="product.image"
                      :src="product.image"
                      :alt="product.name"
                      class="img-responsive"
                    >
                  </div>
                  <div class="products-info-body">
                    <h5>
                      {{ product.name }}
                    </h5>

                    <div class="text">
                      {{ product.description }}
                    </div>
                  </div>
                </b-modal>

                <img
                  v-if="product.image"
                  :src="product.image"
                  :alt="product.name"
                  class="img-responsive"
                >
                <div
                  v-if="product.image"
                  class="category-overlay"
                />

                <span class="products-name">
                  {{ product.name }}
                </span>
                <div class="product-price">
                  {{ currency }} {{ product.price.toFixed(2) }}
                </div>
              </div>
            </div>
            <div class="modifiers-sidebar">
              <div
                v-if="modifiersShow"
                class="modifiers-sidebar-panel"
              >
                <div class="modifiers h-100">
                  <div class="modifiers-top fixed-top">
                    <h2>
                      {{ modifiers.productName }}
                    </h2>

                    <b-button
                      variant="light"
                      @click="modifiersSave()"
                    >
                      {{ $t("modiferspopup.done") }}
                    </b-button>
                  </div>


                  <div class="modifiers-panel">
                    <b-tabs pills>
                      <b-tab
                        v-for="modifierGroup in modifiers.modifierGroupsAtCurrentItem"
                        :key="modifierGroup.id"
                      >
                        <template v-slot:title>
                          <span>
                            {{ countSelectedModifiers(modifierGroup) }}
                          </span>
                          {{ modifierGroup.name }}
                        </template>

                        <div class="modifiers-body">
                          <div
                            v-for="modifier in modifierGroup.modifiers.filter((m) => m.active)"
                            :key="modifier.id"
                            class="modifiers-item"
                            @click="toggleModifierForItem(modifier, modifierGroup)"
                          >
                            <div
                              :class="[ 'modifiers-status', {'modifiers-status_success': isModifierSelected(modifier, modifierGroup) }]"
                            >
                              <svg class="icon icon-icon-check">
                                <use xlink:href="#icon-icon-check" />
                              </svg>
                            </div>

                            <h6 class="modifiers-name">
                              {{ modifier.name }}
                            </h6>

                            <div class="modifiers-price">
                              {{ currency }} {{ modifier.price.toFixed(2) }}
                            </div>
                          </div>
                        </div>
                      </b-tab>
                    </b-tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="d-none d-print-block">
      <PdfReceiptView :order="currentOrder" />
    </div>
    <ModalQuantity
      @save="saveNewQuantity"
    />
    <b-modal
      id="modal-discount"
      :body-bg-variant="bodyBgVariant"
      :modal-class="popupPrice+' '+popupPriceSidebar+' '+'popup-price-btn'"
      no-close-on-backdrop
      no-fade
    >
      <template
        slot="modal-header"
        slot-scope="{ close }"
      >
        <b-button
          variant="outline-light"
          @click="close()"
        >
          {{ $t("discountpopup.cancel") }}
        </b-button>
        <h5>
          {{ $t("discountpopup.discount") }}
        </h5>
      </template>

      <div class="pin-win discount-modal">
        <span
          v-if="discount.type === 1"
        >
          {{ parseFloat(discount.val).toFixed(2) }} %
        </span>
        <span
          v-if="discount.type === 2"
        >
          {{ currency }} {{ parseFloat(discount.val).toFixed(2) }}
        </span>
        <b-button
          v-if="discount.type === 1"
          variant="primary"
          @click="changeDiscountType()"
        >
          {{ $t("discountpopup.percent-off") }}
        </b-button>
        <b-button
          v-if="discount.type === 2"
          variant="primary"
          @click="changeDiscountType()"
        >
          {{ $t("discountpopup.currency-off") }}
        </b-button>
      </div>

      <div class="pin-keyboard text-center my-0">
        <ul class="pin-keyboard-list">
          <li>
            <span
              class="pin-key"
              @click="typeDiscount(1)"
            >1</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeDiscount(2)"
            >2</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeDiscount(3)"
            >3</span>
          </li>

          <li>
            <span
              class="pin-key"
              @click="typeDiscount(4)"
            >4</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeDiscount(5)"
            >5</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeDiscount(6)"
            >6</span>
          </li>

          <li>
            <span
              class="pin-key"
              @click="typeDiscount(7)"
            >7</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeDiscount(8)"
            >8</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeDiscount(9)"
            >9</span>
          </li>

          <li>
            <span
              class="pin-key pin-key_call"
              @click="typeDiscount('C')"
            >C</span>
          </li>

          <li>
            <span
              class="pin-key"
              @click="typeDiscount(0)"
            >0</span>
          </li>

          <li>
            <span
              class="pin-key bg-primary"
              disabled="disabled"
              @click="saveDiscountToItem()"
            >
              <svg class="icon icon-icon-check">
                <use xlink:href="#icon-icon-check" />
              </svg>
            </span>
          </li>
        </ul>
      </div>

      <div
        slot="modal-footer"
        class="w-100"
      >
        <h5>
          {{ $t("discountpopup.discounts") }}
        </h5>

        <ul class="list-unstyled ">
          <li
            v-for="(discountItem, index) in allDiscounts"
            :key="index"
          >
            <a
              href="#"
              @click="setPredefinedDiscount(index)"
            >
              <span>
                {{ discountItem.name }}
              </span>
            </a>
          </li>
        </ul>
      </div>
    </b-modal>
    <EnterPinModal @verify-action="verifyAction" />

    <SimpleInfoModal :id="'modal-product-not-found'">
      <template v-slot:title>
        {{ $t("order.product.not-found") }}
      </template>
      <template v-slot:info>
        {{ $t("order.product.not-found") }}. {{ $t("modal-info.try-again") }}
      </template>
    </SimpleInfoModal>

    <SelectCustomer
      :order-customer="currentOrder.customer"
      @select-customer="selectCustomer"
      @remove-customer="removeCustomerFromOrder"
    />
    <DiningOptionModal
      v-if="terminal"
      :dining-options="terminal.dining_options"
      :dining-option="currentOrder.dining_option_type"
      @select-dining-option="selectDiningOption"
    />
    <CustomerModal
      :view-customer="viewCustomer"
      :is-selected="true"
      :index="1"
      @close-modal="clearViewCustomer"
    />
    <ModalItemNotes @saveNote="saveNote($event)" />
    <ModalPriceAdjust @savePrice="savePrice($event)" />
    <ModalPriceAdjust
      id="gift-card-modal"
      @saveGiftCard="saveGiftCard($event)"
    />
    <scale-loader
      :loading="isLoad"
      :color="'#007bff'"
    />
    <ModalError
      :id="errorModal.modalId"
      :error="errorModal.errorBody"
      @hide-modal="errorModalCallBack"
    />
  </pos-layout>
</template>

<script>
import router from "../router";
import { ORDER_STATUS } from "@/helpers/order/status";
import { EMPLOYEE_ROLE } from "@/helpers/employee/role";
import orderMixin from "../mixins/order/orderMixin";
import printMixin from "../mixins/printMixin";
import syncMixin from "../mixins/syncMixin";
import orderModifierMixin from "../mixins/order/modifier";
import { COLORS } from "@/helpers/colors";
import viewCustomerModalMixin from "../mixins/modals/viewCustomerModalMixin";
import PosLayout from "../layouts/PosLayout";
import ModalItemNotes from "../components/ModalItemNotes";
import ModalPriceAdjust from "../modals/ModalPriceAdjust";
import NumPad from "../components/Order/Search/NumPad";
import SimpleInfoModal from "../components/SimpleInfoModal";
import SelectCustomer from "../modals/SelectCustomerModal";
import CustomerModal from "@/modals/CustomerModal";
import PdfReceiptView from "../components/PdfReceiptView";
import EnterPinModal from "../modals/EnterPinModal";
import DiningOptionModal from "../modals/Order/DiningOptionModal";
import { TAX_OPTION } from "@/helpers/store/tax_options";
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
import { toFixed } from "@/helpers/utils";
import * as Sentry from "@sentry/vue";
import {
  calculateItemTaxTotal,
  getItemGrandTotal, getItemSubtotal, getOrderGrantTotal
} from "@/helpers/calc";
import compareArrays from "@/utils/compareArrays";
import ModalQuantity from "@/modals/ModalQuantity";
import { mapActions, mapGetters } from "vuex";
import OrderPanel from "@/components/Order/OrderPanel"
import cdOrderMixin from "@/mixins/cdOrderMixin";
import ModalError from "../modals/ModalError";
import customerDisplayMixin from "@/mixins/customerDisplayMixin";
// import { StreamBarcodeReader } from "vue-barcode-reader";
import { GIFT_CARD_TRANSACTION_TYPE } from "@/helpers/order/transaction/types";

Array.prototype.equals = compareArrays;
// Hide method from for-in loops
Object.defineProperty(Array.prototype, "equals", { enumerable: false });
export default {
  name: "Order",
  components: {
    CustomerModal,
    EnterPinModal,
    PosLayout,
    ModalItemNotes,
    ModalPriceAdjust,
    NumPad,
    SimpleInfoModal,
    SelectCustomer,
    DiningOptionModal,
    PdfReceiptView,
    ScaleLoader,
    ModalQuantity,
    OrderPanel,
    ModalError
  },
  mixins: [orderMixin, printMixin, orderModifierMixin, viewCustomerModalMixin, syncMixin, cdOrderMixin, customerDisplayMixin],
  data () {
    return {
      unsubscribeOrdersListener: null,
      bufferHelper: {
        itemsSavingCounter: 0,
        nextRoute: ""
      },
      visible: false,
      isLoad: false,
      syncing: null,
      initialSyncStarted: false,
      layout: "normal",
      sidebarMob: false,
      baseURL: null,
      isTaxed: true,
      swiperOption: {
        slidesPerView: "auto",
        spaceBetween: 0,
        freeMode: true,
        slideToClickedSlide: true,
        threshold: 30,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        breakpoints: {
          992: {
            slidesPerView: "auto",
            spaceBetween: 0,
            freeMode: true,
            slideToClickedSlide: false
          }
        }
      },
      search: {
        value: "",
        visible: false,
        results: []
      },
      currentCategory: 0,
      itemIdCounter: 0,
      currentOrder: {
        customer: null,
        localID: 0,
        subtotal: 0,
        items: [],
        table: null,
        tax_total: 0,
        discount_total: 0,
        order_discount: 0,
        status: 1,
        open_date: null,
        number: "0",
        discount_orders: 0,
        payments: [],
        receipt_kitchen_printed: false,
        description: "",
        dining_option_type: null,
        cash_discount: false,
        discount_orders_raw_value: null,
        discount_orders_raw_type: null,
        // order_gift_card_payment:[],
        isActive:false //isActive variable to determine if current order is open the pos window
      },
      discount: {
        val: "",
        changed: false,
        type: 1
      },
      orders_discount: {
        val: 0,
        changed: false,
        type: 1
      },
      bodyBgVariant: "light",
      popupPrice: ["popup-price"],
      popupPriceSidebar: ["popup-price-sidebar"],
      currentEmployee: null,
      // allDiscounts: [],
      events: {},
      isDisplayPaymentDetails: false,
      transformColor: COLORS,
      isVerified: true,
      lockedAction: false,
      lockedItemId: 0,
      paginatedProducts: [],
      pagination: {
        page: 1,
        perPage: 50,
        pageCount: 0
      },
      storeInfo: {},
      filteredProductsByCategory: [],
      default_dining_option: null,
      isKitchenPrintProcessing: false,
      modalItemIndex: null,
      releaseLockedBy: true,
      firstSync:false,
      errorModal:{
        modalId: "complete-order-error",
        errorBody: {
          title: "Order you are trying to open is completed",
          info: `You can edit only Open orders...`
        }
      },
      isAddingItem: false,
    };
  },
  computed: {
    ...mapGetters({
      isTaxInclusive: "config/isTaxInclusive",
      isOnline: "connection",
      terminalMode: "terminalMode",
      isShiftOpen: "isShiftOpen",
      products: "config/products",
      categories: "config/categories",
      employee: "employee",
      store: "config/store",
      isSavingProcess: "isSavingProcess",
      isKioskMode: "config/isKioskMode",
      updatingOrder: "orders/getUpdatingOrder",
      currency: "config/currency",
      refundItems: "orders/refundItems",
      refundOrderId: "orders/refundOrderId",
      refundedOrderTableId: "orders/refundedOrderTableId",
      emptyCurrentOrder: "orders/emptyCurrentOrder",
      refundedOrdersList: "orders/refundedOrdersList",
      allDiscounts: "config/discounts", // it send back all the discount presets
      refundedOrderDiscountTotalDetails:"orders/refundedOrderDiscountTotalDetails", // discount totsl attributed of the reference order
      discountDetails: "discount/discountDetails",
      terminal: 'config/terminal',
      orders: 'orders/getAllOrders',
      discountArray:'config/discounts',
      orderInitailSync:'orders/orderInitailSync',
      allTables:"config/getAllTables",
      activeGiftCard:"giftVoucher/activeGiftCard",
      selectedGiftCards:"giftVoucher/selectedGiftCards",
      showSelectedOrder: 'orders/showSelectedOrder'

      // ...
    }),
    currentOrderSum: function () {
      const orderSumValue = getOrderGrantTotal(this.currentOrder, this.products, this.isTaxInclusive) - this.currentOrderCashSum;
      return parseFloat(orderSumValue.toFixed(2));
    },
    paymentsCashEntries: function () {
      return this.currentOrder.payments ? this.currentOrder.payments : [];
    },
    currentOrderCashSum: function () {
      let sum = 0;
      this.paymentsCashEntries.map(payment => sum += payment.amount);
      return sum;
    },
    defaultDiningOption () {
      if (!this.terminal) return null;
      return this.terminal["dining_options"].find(option => this.store.default_dining_option === option.id);
    },
    orderItems() {
      return this.currentOrder.items.filter(function (item) {
        return item.void_status !== 1;
      });
    },
  },
  watch: {
    currentOrderSum (value) {
      this.currentOrder.balance_remaining = Math.max(0, value);
    },
    modifiersShow (value) {
      if (!value) this.modifiersSave();
    },
    currentCategory:{
    handler (value) {
      this.filteredProductsByCategory = this.products.filter(item => {
          // check if the terminal mode is kiosk then include products which are kiok related
          if(this.isKioskMode)
            return item.categories.includes(value) && item.order_kiosk;
        return item.categories.includes(value);
      }).sort(((a, b) => a.sorting - b.sorting || a.name.localeCompare(b.name)));
      this.pagination.pageCount = Math.ceil(this.filteredProductsByCategory.length / this.pagination.perPage);
      this.paginatedProducts = [];
      this.pagination.page = 1;
      this.getProductListPaginated();
    },
    immediate:true
  },
    "currentOrder.id" (value) {
      if (value)
        router.replace({ name: "Order", params: { id: value } }, () => {
        });
    },
    updatingOrder() {
      if (this.currentOrder.local_id === this.updatingOrder.local_id) {
        this.currentOrder = {
          ...this.currentOrder,
          id: this.updatingOrder.remote_id
        }
      }
    },
    currentOrder: {
      handler() {
        this.sendOrderToChannel(this.currentOrder)
      },
      deep:true
    },
    emptyCurrentOrder: {
      immediate: true,
      async handler() {
        if (this.emptyCurrentOrder) {
          await this.emptyOrder()
          this.sendOrderToChannel(this.currentOrder)
          this.setEmptyCurrentOrder(false)
        }
       }
    },
    "currentOrder.isActive": {
      handler(isActive) {
        try {
          // setting active state in order to control the showing of leaveCurrentModal on the isActive attribute
            if (isActive)
              this.setActiveOrderState(true)
            else {
              this.setActiveOrderState(false)
            }
        }
        catch (err) {
          Sentry.captureException(err);
          //pass
        }
      },
      immediate: true

    },
  },
  async created() {
    // fetching data from index db when state is loss
    if(this.orders.length === 0)
      await this.fetchOrdersFromDb()
    if (this.categories.length === 0)
      await this.fetchCategoriesFromDb()
    if (this.products.length === 0){
      await this.fetchProductsFromDb()
      // await this.fetchGiftCardsAndPaymentsFromDb()
    }
    if (this.discountArray.length === 0)
      await this.fetchDiscountsFromDb()
    if(this.allTables.length === 0)
      await this.fetchTablesFromDb()

    if (!this.$route.params.id) {
      throw Error("Could not get order id from url");
    }
    if (!this.isShiftOpen && !this.isKioskMode) return await this.$router.push({ name: "Shift" });
    if (!this.categories || this.categories.length === 0) {
      throw Error(`No categories available, ${this.categories ? JSON.stringify(this.categories) : this.categories}`);
    }
    // always pre-select the first category
    this.currentCategory = this.categories[0].id;
    if (!this.isKioskMode && this.employee.role === EMPLOYEE_ROLE.Cashier) {
      this.isVerified = false;
    }
    this.default_dining_option = this.isKioskMode ? this.$route.params.diningOption : this.store.default_dining_option;

    // if the order is to be refunded check for refunded items.
    if (this.refundItems.length > 0) {

      const refundItems = this.refundItems

      const itemsToRefund = refundItems.map(refundItem => {
        // only target those item who status is 0 i.e. they were not deleted previously
        const targetObject = this.products.find(item => item.id === refundItem.product && (refundItem.void_status === 0 || refundItem.void_status === null ) && refundItem.duplicateQuantity >0)

        // setting boolean state so that number of calculations to be done per item can be controlled
        this.setDeductRefundQuantity(true)

        if (targetObject) {
          return {
            quantity: -1 * (refundItem.duplicateQuantity || 1),
            modifiers: refundItem.modifiers,
            refundedPrice : refundItem.price,
            refundedDiscount: refundItem.discount,
            refundItemId: refundItem.id,
            refundModifiers: true,
            refundItemTax_status : refundItem.tax_status,
            ...targetObject
          }
        } else {
          return null
        }
      }).filter(x => !!x)

      for (let index = 0; index < itemsToRefund.length; index++) {
        const item = itemsToRefund[index]
        // add all the refunded items to the order
        await this.addItemToOrder(item)
      }
       this.setRefundItems([])
    }

    await this.init();
    if (this.$route.params.id && this.$route.params.id !== "new") {
      this.checkForCompletedOrder(this.$route.params.id)
    }
  },
  async beforeDestroy () {
    clearInterval(this.syncing);
    window.removeEventListener("keypress", this.onKeyPress);
    // unlock open orders if the route changes.
    if ((this.currentOrder.local_id || this.currentOrder.id) && this.releaseLockedBy) {
      this.currentOrder.locked_by = null;
      this.isLoad = true;
      this.isLoad = false;
      await this.$store.commit("orders/saveOrder", this.currentOrder);
      if (this.currentOrder.status !== ORDER_STATUS.CANCELLED) {
        await this.$store.dispatch("sync", 'BEFORE DESTROY');
      }
      if (this.$route.name === "Tables") {
        // toggle callOrderList after the order has been synced and the next route is Table
        this.$store.dispatch('toggleCallOrderList', true)
      }
    }
    // need to toggle it even if there is no order initiated
    if (this.$route.name === "Tables") {
      this.$store.dispatch('toggleCallOrderList', true)
    }
  },
  mounted () {

    if (this.$route.params.id && this.$route.params.id !== "new") {
      this.checkForCompletedOrder(this.$route.params.id)
    }

    this.releaseLockedBy = true
    const container = document.querySelector("#productWrapper");
    const self = this;
    container.addEventListener("scroll", async function () {
      const userScrollHeight = container.scrollTop + container.clientHeight;
      const threshold = userScrollHeight + container.scrollHeight * 0.3;
      if (threshold - container.scrollHeight > -2 && self.pagination.page < self.pagination.pageCount) {
        self.pagination.page++;
        self.getProductListPaginated();
      }
    });
    // todo fix remaining button from prev page
    window.addEventListener("keypress", this.onKeyPress);
    this.$root.$on("bv::modal::show", (bvEvent, modalId) => {
      switch (modalId) {
        case "enter-pin-modal":
        case "enter-pin-modal--order-discount":
          window.removeEventListener("keydown", this.onKeyItemDiscount);
          window.removeEventListener("keydown", this.onKeyQty);
          break;
      }
      window.removeEventListener("keypress", this.onKeyPress);
    });
    this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
      switch (modalId) {
        case "enter-pin-modal":
        case "enter-pin-modal--order-discount":
          window.addEventListener("keydown", this.onKeyItemDiscount);
          window.addEventListener("keydown", this.onKeyQty);
          break;
        case "modal-discount":
          window.removeEventListener("keydown", this.onKeyItemDiscount);
          break;
        case "modal-quantity":
          window.removeEventListener("keydown", this.onKeyQty);
          break;
        case "customer-modal":
        case "customer-modal-1":
          return window.removeEventListener("keypress", this.onKeyPress);
      }
      window.addEventListener("keypress", this.onKeyPress);
    });
  },
  methods: {
    ...mapActions('orders', ['setRefundItems', 'setDeductRefundQuantity', 'setEmptyCurrentOrder', 'setRefundedOrderTotalDiscount','fetchOrdersFromDb','setOrderInitailSync']),
    ...mapActions('discount',['setOrderDiscount']),
    ...mapActions('config',['fetchCategoriesFromDb','fetchProductsFromDb','fetchDiscountsFromDb','fetchTablesFromDb']),
    ...mapActions('giftVoucher',['fetchGiftCardsAndPaymentsFromDb','fetchGiftCardPayments','fetchGiftCard','setSelectedGiftCards']),
    showNotesModal (index) {
      this.$store.commit("itemNote/setIndex", index);
      this.$store.commit("itemNote/setNotes", this.currentOrder.items[index].notes);
      this.$bvModal.show("modal-note-order-item");
    },
    showPriceAdjustModal (item) {
      this.$store.commit("itemPriceAdjust/setItem", item);
      const index = this.currentOrder.items.findIndex(i => i.local_id === item.local_id);
      this.$store.commit("itemPriceAdjust/setIndex", index);
      this.$store.commit("itemPriceAdjust/setPrice", this.currentOrder.items[index].price);
      this.$bvModal.show("modal-price-adjust");
    },
    setOrderChannel (channel) {
      if (channel) {
        try {
          // temporary disabled as it gives errors
          // channel.send(JSON.stringify({...this.currentOrder, pay_total: this.currentOrderSum}));
        } catch (e) {
          Sentry.captureException(e);
        }
      }
    },
    showDiningOptionsModal () {
      return this.$bvModal.show("dining-options-modal");
    },
    selectDiningOption (optionId) {
      this.currentOrder.dining_option_type = optionId;
      this.currentOrder.dining_option = this.orderDiningOption.name;
      this.sync();
    },
    async selectCustomer (customer) {
      return this.assignCustomerToOrder(customer);
    },
    async showSelectCustomerModal () {
      if (this.isOnline) {
        this.isLoad = true;
        try {
          await this.$store.dispatch("customers/fetch");
        } catch (e) {
          console.error(e);
          Sentry.captureException(e);
        }
        this.isLoad = false;
      }
      return this.$bvModal.show("select-customer-modal");
    },
    onKeyPress () {
      // todo, outside
      try {
        this.$refs.searchField.focus();
      } catch (e) {
        //
      }
    },
    findProduct () {
      if (this.search.value === "") {
        this.search.visible = false;
      }
      else {
        let searchValue = 0
        //first search for products with whole barcode without breaking it done
        let products = this.searchProductByName(this.search.value);
        if(products.length === 0){
          // if the whole barcode is not found than break it down for SKU
          searchValue = this.checkForSKU(this.search.value)
          products = this.searchProductByName(searchValue?.value);
        }
        if (products.length === 0) {
          this.search.value = "";
          this.search.visible = false;
          const sound = new Audio(require("../assets/sounds/error-sound.mp3"));
          sound.play();

          this.$bvModal.show("modal-product-not-found");
        }
        else {
          const selectedProduct = {...products[0]}
          if(searchValue.price)
          {
            selectedProduct.price = searchValue.price
            selectedProduct.price_base = searchValue.price
          }
          this.addItemToOrder(selectedProduct);
        }
      }
    },

    checkForSKU(barcode){
      /**
       * This function is used to break the barcode into SKU and price, with lengths 12 and 13 and code 20 or 20
       */
      const barCode = {}
      if(barcode.length === 12){
      // slice the barcode first two digits for the type 02 or 20
      const barCodeType =  barcode.slice(0,2)
        // extract SKU and price as value and price respectively
        if (barCodeType === '20' || barCodeType === '02'){
          barCode.value = barcode.slice(2,7)
          barCode.price = (parseInt(barcode.slice(7,11))/100 )
          return barCode
        }
        else{
          barCode.value = barcode
          return barCode
        }
      }
      else if(barcode.length === 13){
        // slice the barcode first two digits for the type 02 or 20
        const barCodeType =  barcode.slice(0,2)
        // extract SKU and price as value and price respectively
        if(barCodeType === '20' || barCodeType === '02' ){
          barCode.value = barcode.slice(2,7)
          barCode.price = (parseInt(barcode.slice(7,12))/100)
          return barCode
        }
        else{
          barCode.value = barcode
          return barCode
        }
      }
      else{
        barCode.value = barcode
        return barCode
      }
    },
    onKeyItemDiscount (event) {
      this.typeDiscount(this.getNum(event));
    },
    getNum (event) {
      let num = 0;
      if (event.keyCode >= 48 && event.keyCode <= 57) {
        num = event.keyCode - 48;
      } else if (event.keyCode === 8) {
        num = "C";
      }

      return num;
    },
    init: async function () {
      if (this.$route.params.diningOption) {
        this.currentOrder.dining_option_type = this.$route.params.diningOption;
      }
      if (!this.terminal) {
        console.error("No terminal at init order stage", this.terminal);
        Sentry.captureException("No terminal at init order stage, this.terminal");
      }

      // set terminal of the new order initiated
      this.currentOrder.related_terminal = this.terminal ? this.terminal.id : null;

      // set dinning options
      if (!this.currentOrder.dining_option_type && this.default_dining_option) {
        this.currentOrder.dining_option_type = this.default_dining_option;
        this.currentOrder.dining_option = this.terminal["dining_options"].find(option => this.currentOrder.dining_option_type === option.id)?.name;
      }

      // if the order exists then load the order from the state else set table id, if present in routes
      if (this.$route.params.id && this.$route.params.id !== "new") {
        const orderKey = this.$route.params.id.toString().includes("_") ? this.$route.params.id
          : parseInt(this.$route.params.id);
        await this.currentOrderInit(orderKey);
      } else {
        this.currentOrder.table = this.$route.params.tableID ? this.$route.params.tableID : this.currentOrder.table;
      }
    },
    async currentOrderInit (orderID) {
      // parsing order key
      const orderKey = orderID.toString().includes("_") ? orderID
        : parseInt(orderID);
      this.currentOrder = {...this.$store.getters["orders/getOrderByIdOrLocalId"](orderKey)}
      if (this.currentOrder.status === ORDER_STATUS.COMPLETED) {
        this.$store.dispatch("errorModal/displayModalError", {
          modalId: "order-error",
          errorBody: {
            title: "Order you are trying to open is completed",
            info: `You can edit only Open orders...`
          }
        });
      }
      // check that all products are available
      for (const item of this.currentOrder.items) {
        const product = this.products.find((prod) => prod.id == item.product);
        if (!product) {
          this.$store.dispatch("errorModal/displayModalError", {
            modalId: "order-error",
            errorBody: {
              title: "Product not found",
              info: `Please, check that product with id "${item.product}" is active and available for current store`
            }
          });
          throw Error(`Not found product with id ${item.product}`);
        }
      }

      // lock the order  setting the terminal id in the locked_by field
      this.currentOrder.locked_by = this.terminal ? this.terminal.id : null;
      //count subtotal
      await this.countSubtotal();
      // set refund table for refund order
      if (this.refundOrderId && !this.$route.params.tableID) {
        this.currentOrder.table = this.refundedOrderTableId
      }
      else {
        this.currentOrder.table = this.$route.params.tableID ? this.$route.params.tableID : this.currentOrder.table;
      }
      // sync the order object to the backend
      await this.sync();
    },
    saveNote (noteReturnObj) {
      const itemIndex = this.$store.getters["itemNote/index"];
      this.currentOrder.items[itemIndex].notes = noteReturnObj.notes;
      this.sync();
    },
    async savePrice (returnObj) {
      /**
       * This methods overrides the price of the actual product
      */
      // fetch the item of which the price is to be adjusted
      const item = this.$store.getters["itemPriceAdjust/item"];
      if (!item) console.error("[savePrice] No item found");
      // fetch the product
      const product = this.products.find(prod => prod.id === item.product);
      if (!product) console.error("[savePrice] No product found");
      const index = this.$store.getters["itemPriceAdjust/index"];

      // update item price
      let currentOrderitems = [...this.currentOrder.items]
      currentOrderitems[index].price = returnObj.price;
      currentOrderitems[index].price_base = returnObj.price
      // eslint-disable-next-line no-unused-vars
      let newDiscount = 0;

      // discount is calculated for individual items
      if (this.discount.type === 1) { // discount is in percentage

        //calculate mode prices
        const mods = this.currentOrder.items[index].modifiers.filter(mod => mod.void_status === false);
        let modSum = 0;
        for (const mod of mods) modSum += mod.price;

        //calcualte discount items
        newDiscount = parseFloat((this.currentOrder.items[index].price + modSum) * (parseFloat(this.discount.val) / 100).toFixed(2));
        if (newDiscount < (this.currentOrder.items[index].price + modSum)) {
          currentOrderitems[index].discount = newDiscount
        }
        // if discount is greater than the price then reset the discount to zero
        else {
          currentOrderitems[index].discount = 0.00
        }
      }
      // discount is in currency
      else {
      // if discount is greater than the price then reset the discount to zero
        if (currentOrderitems[index].discount > currentOrderitems[index].price)
          currentOrderitems[index].discount = 0
      }

      //calculate taxes
      currentOrderitems[index]["tax_items"] = this.calculateItemTaxItems(currentOrderitems[index]);
      if (this.isTaxInclusive) {
        currentOrderitems[index].price_before_tax = parseFloat(toFixed(returnObj.price - this.getItemAllTaxSum(currentOrderitems[index], true), 2));
      } else {
        currentOrderitems[index].price_before_tax = parseFloat(toFixed(returnObj.price, 2));
      }
      currentOrderitems[index].tax_status = returnObj.taxed ? 0 : 1;
      currentOrderitems[index].tax = returnObj.taxed ? this.getItemAllTaxSum(currentOrderitems[index]) : 0;

      //calculate subtotal and grand_total
      currentOrderitems[index].subtotal = getItemSubtotal(item);
      currentOrderitems[index].grand_total = parseFloat((getItemGrandTotal(item, this.store.tax_option === TAX_OPTION.TAX_INCLUSIVE)).toFixed(2));

      this.currentOrder.items = [...currentOrderitems]

      //calculate all the essential calculation and then sync
      this.countSubtotal()
      this.sync(false, true);
    },
    showSearch () {
      const barCodeNumPad = this.$refs.barcodeDropdown;
      if (!this.search.visible && !barCodeNumPad.visible) {
        this.search.visible = true;
      }
    },
    hideSearch () {
      this.search.visible = false;
    },
    onInputChangeSearch (input) {
      this.search.value = input;
      this.doSearch();
    },
    doSearch () {
      if (this.search.value.length > 0) {
        this.search.visible = true;
        if (this.search.value.length > 3)
          this.search.results = this.searchProductByName(this.search.value);
      } else {
        this.search.visible = false;
        this.search.results = [];
      }
    },
    sidebarMobToggle () {
      this.sidebarMob = !this.sidebarMob;
    },
    taxedToggle () {
      this.isTaxed = !this.isTaxed;
    },
    createNewOrder () {
      this.currentOrder.locked_by = this.terminal ? this.terminal.id : null;
      this.currentOrder.local_id = `${this.terminal.id}_${Date.now()}`;
      this.currentOrder.open_date = new Date().toISOString();
      this.currentOrder.emp_open = this.employee ? this.employee.id : null;
      this.currentOrder.updated_at = new Date().toISOString();
      if (this.default_dining_option) {
        this.currentOrder.dining_option_type = this.default_dining_option;
      }
    },
    resetSearch: function () {
      this.search = {
        visible: false,
        value: "",
        results: []
      };
      this.hideBarcodeNumpad();
    },
    addItemToOrder: async function (product) {
      /**
       * Adding the selected prodcuts to the order
       * */

      // If already adding an item, do not proceed
      if (this.isAddingItem) return;
      this.isAddingItem = true; // lock

      try {
        this.bufferHelper.itemsSavingCounter++;
        this.resetSearch();
        // create a new order if the route params contains 'new'
        if (this.$route.params.id === "new" && !this.currentOrder.id) {
        this.createNewOrder();
          router.replace({ name: "Order", params: { id: this.currentOrder.local_id } });
        }

        //setting isActive variable to determine if current order is open the pos window
        this.currentOrder.isActive = true
        this.currentOrder.updated_date = Date.now();
        this.currentOrder.updated_at = new Date().toISOString();
        this.currentOrder.terminal_id = this.terminal.id;
        let itemPrice = parseFloat(toFixed(product.refundedPrice? product.refundedPrice :product.price, 2));
        //update products if there is any special price item
        if (product.special_prices_items.length && this.currentOrder.customer && this.currentOrder.customer.group) {
          product.special_prices_items.map(specialPrice => {
            if (specialPrice.group !== this.currentOrder.customer.group) return;
            itemPrice = specialPrice.price;
          });
        }

        if (this.currentOrder.items.length === 0) {
          await this.sync(true, true);
        }

        // create a local id for the item
        const localItemId = this.getItemIterator();
        const newIndex = this.currentOrder.items.length;
        const newItem = {
          product: product.id,
          name: product.name,
          quantity: product.quantity ? product.quantity : 1,
          price: itemPrice,
          price_base: itemPrice,
          price_before_tax: itemPrice,
          subtotal: itemPrice,
          grand_total: itemPrice,
          cost: (product.cost || 0),
          id: localItemId,
          local_id: localItemId,
          discount: product.refundedDiscount ? product.refundedDiscount: 0 ,
          modifiers: [],
          notes: "",
          void_status: 0,
          employee: this.employee ? this.employee.id : null,
          printers: product.printers,
          has_kitchen_updates: !!(product.printers.length),
          sent_to_kitchen: false,
          tax_rate_value: product.tax_rate_value,
          tax_status: product.tax_rate_value ? 0 : 1,
          special_prices_items: product.special_prices_items,
          default_price: product.price,
          terminal_id: this.terminal.id,
          tax: 0,
          created_at: new Date().toISOString(),
          wholeDiscount : 0,
          discountPropotion : 0
        };
        // if the order is refunded add the discounts as well
        if (product.refundItemId) {
          newItem.order_refund_item = product.refundItemId
          newItem.tax_status = product.refundItemTax_status
          // if there is a discount total applied previously to the referenced order
          if (this.refundedOrderDiscountTotalDetails.value && this.refundedOrderDiscountTotalDetails.value !== 0) {
            this.currentOrder.discount_orders_raw_value = this.refundedOrderDiscountTotalDetails.value;
            this.currentOrder.discount_orders_raw_type = this.refundedOrderDiscountTotalDetails.discountType;
            this.currentOrder.discount_orders = this.refundedOrderDiscountTotalDetails.value;
          }
        }
        if (product.refundItemId)
            newItem.void_item = 0
        //add the new item to order item list
        this.currentOrder.items.push(newItem);
        // calculate tax
        this.currentOrder.items[newIndex].tax_items = this.calculateItemTaxItems(newItem);
        this.currentOrder.items[newIndex].tax = calculateItemTaxTotal(this.currentOrder.items[newIndex]);

        if (this.isTaxInclusive) {
          this.currentOrder.items[newIndex].price = itemPrice;
          this.currentOrder.items[newIndex].price_before_tax = parseFloat(toFixed(itemPrice - this.getItemAllTaxSum(this.currentOrder.items[newIndex], true), 2));
        }
        this.currentOrder.balance_remaining = Math.max(0, this.currentOrderSum);
        const itemId = (this.currentOrder.items[newIndex]).id;

        if(!product.refundModifiers) // donot open modifiers screen when refunding items
        if (product["modifier_groups"].length > 0) this.modifiersOpen(itemId, true);
        if (product["price_adjust"]) {
          this.showPriceAdjustModal(this.currentOrder.items[(newIndex)]);
        }
        if (product["qty_adjust"]) this.changeQuantity(itemId);

        if (!product["modifier_groups"].length) {
          this.calculateItemCashDiscountChange(this.currentOrder.items.length - 1, true);
        }
        // sync every new item added
        await this.countSubtotal();
        await this.sync(false, false);
        //saving modifiers after adding the item
        if(product.refundModifiers)
          this.savePredefinedModifiers(product.modifiers,this.products.find(foundProduct => foundProduct.id === product.id )?.modifier_groups,this.currentOrder.items.length-1)

      } catch (error) {
        console.error('Error adding item to order:', error);
        // You might want to show an error message to the user here
      } finally {
        this.bufferHelper.itemsSavingCounter--;
        this.isAddingItem = false; // Always release the lock
      }
    },
    selectCategory: function (catId) {
      this.currentCategory = catId;
    },
    getProductListPaginated () {
      const products = this.filteredProductsByCategory;
      this.paginatedProducts = [...this.paginatedProducts, ...products.splice(0, this.pagination.perPage)];

      return this.paginatedProducts;
    },
    async holdOrder (payload) {
      if (this.$route.params.id === "new" || this.currentOrder.id === 0) return true;
      // set the current order status to hold
      this.currentOrder = {
        ...this.currentOrder,
        status: ORDER_STATUS.HOLD,
        hold_date: new Date().toISOString(),
        description: payload.description,
        locked_by:null
      };
      // It was making duplicate items
      // await this.sync();

      if(this.showSelectedOrder){
        this.$router.push({name:'OrdersList'})
        this.setActiveOrderState(false)
        return null;
      }

      if (!this.currentOrder.table) {
        await router.push({
          name: "NewOrderRedirect",
          params: {}
        }, () => {
        });
        return null;
      }

      this.table = this.$store.getters.getTableWithOrders(this.currentOrder.table);
      this.table.tableOrders.length === 0
        ? await router.push({ name: "Tables" })
        : await router.push({ name: "TableOrders", params: { id: this.currentOrder.table } }, () => {
        });
    },
    updateLocalId: async function (msgObj) {
      if (!this.currentOrder || !this.currentOrder.id) return true;

      if (this.currentOrder.id === msgObj.localID && this.currentOrder.id !== msgObj.remoteID) {
        this.currentOrder.id = msgObj.remoteID;
        if (this.$route.name === "Order") {
          await router.replace({ name: "Order", params: { id: msgObj.remoteID } }, () => {
          });
        }
        await this.sync();
      }
    },
    updateCustomerId: async function (msgObj) {
      if (!this.currentOrder || !this.currentOrder.customer) return true;
      if (this.currentOrder.customer.id === msgObj.localID && this.currentOrder.customer.id !== msgObj.remoteID) {
        this.currentOrder.customer.id = msgObj.remoteID;
      }
    },
    searchByBarcode: function (num) {
      this.search.value = num;
      if (num.length === 0) return false;
      let searchValue = {
        value:''
      }
        searchValue.value = this.search.value
        let products = this.searchProductByName(this.search.value); // 84000
        if(products.length === 0){
          searchValue = this.checkForSKU(this.search.value)
          products = this.searchProductByName(searchValue?.value);
        }

      if (products.length === 0) {
        this.search.value = "";
        this.search.visible = false;

        return this.$bvModal.show("modal-product-not-found");
      }
      else{
        const selectedProduct = {...products[0]}
        if(searchValue.price)
        {
          selectedProduct.price = searchValue.price
          selectedProduct.price_base = searchValue.price
        }
         return this.addItemToOrder(selectedProduct);
      }
    },
    barcodeInputChange: function (num) {
      this.search.value = num;
    },
    hideBarcodeNumpad: function () {
      this.$refs.barcodeDropdown ? this.$refs.barcodeDropdown.hide(true) : "";
    },
    typeDiscount: function (num) {
      if (num === "C") {
        this.discount.raw = "0";
        this.discount.val = "0";
      } else {
        if (this.discount.changed) {
          if (this.discount.raw.length < 8) {
            this.discount.raw = this.discount.raw.concat(num + "");
          }
          this.discount.val = (parseFloat(this.discount.raw) / 100).toString();
        } else {
          this.discount.raw = num + "";
          this.discount.val = (parseFloat(this.discount.raw) / 100).toString();
        }
        this.discount.changed = true;
      }
    },
    changeDiscount: async function (itemId) {
      const index = this.currentOrder.items.findIndex(currentItem => currentItem.id === itemId);
      this.discount.changed = false;
      // convert values back to percentage if the discount was given in percentage
      if (this.discountDetails.type === 1) {
        // calculate modSum first
        const mods = this.currentOrder.items[index].modifiers.filter(mod => mod.void_status === false);
        let modSum = 0;
        for (const mod of mods) modSum += mod.price;
        //reverting values back to percentage to show in the discount modal
        this.discount.val = parseFloat((parseFloat(this.currentOrder.items[index].discount) * 100) / (this.currentOrder.items[index].price + modSum).toFixed(2)) + "";
        this.discount.raw = parseFloat((parseFloat(this.currentOrder.items[index].discount) * 100) / (this.currentOrder.items[index].price + modSum).toFixed(2))  + "";
        this.discount.type = this.discountDetails.type;
        this.discount.index = index;
      } else {
        this.discount.val = this.currentOrder.items[index].discount + "";
        this.discount.raw = this.currentOrder.items[index].discount + "";
        this.discount.type = 2;
        this.discount.index = index;
      }
      window.addEventListener("keydown", this.onKeyItemDiscount);
      // render modal
      this.$bvModal.show("modal-discount");
    },
    changeDiscountType: async function () {
      this.discount.type = this.discount.type === 1 ? 2 : 1;
    },
    setPredefinedDiscount: async function (index) {
      this.discount.type = this.allDiscounts[index].type;
      this.discount.val = this.allDiscounts[index].value;
    },
    saveDiscountToItem: function () {
      /**
       * This function is responsible for applying discount on item level
      */
      if (!this.employee) return false;
      if (this.employee.role && this.employee.role === EMPLOYEE_ROLE.Cashier && !this.isVerified) {
        this.lockedAction = "item-discount";
        return this.$bvModal.show("enter-pin-modal");
      }
      const index = this.discount.index;
      if (this.discount.val !== "") {
        let newDiscount;
        if (this.discount.type === 2) {
          newDiscount = parseFloat(parseFloat(this.discount.val).toFixed(2));
          // setting discount type and val to the store
          this.setOrderDiscount(this.discount)
        } else {
          // if the discount is in percentage
          const mods = this.currentOrder.items[index].modifiers.filter(mod => mod.void_status === false);
          let modSum = 0;
          for (const mod of mods) modSum += mod.price;
          newDiscount = parseFloat((this.currentOrder.items[index].price + modSum) * (parseFloat(this.discount.val) / 100).toFixed(2));
          // setting discount type and val to the store
          this.setOrderDiscount(this.discount)
        }
        window.removeEventListener("keydown", this.onKeyItemDiscount);
        //calculate tax
        const discountedItem = {
          ...this.currentOrder.items[index], ...{
            discount: newDiscount,
            tax_items: this.calculateItemTaxItems(this.currentOrder.items[index]),
            tax: this.currentOrder.items[index].tax_status === 0 ? parseFloat(toFixed((calculateItemTaxTotal(this.currentOrder.items[index])), 2)) : 0
          }
        };
        discountedItem.subtotal = getItemGrandTotal(discountedItem);
        //show error modal if the discount is greater then the subtotal of the order
        if (discountedItem.subtotal < 0) {
          this.$store.dispatch("errorModal/displayModalError", {
            modalId: "order-error",
            errorBody: {
              title: "Value error",
              info: `Discount amount is more than the value requested`
            }
          });
          return;
        }
        this.$bvModal.hide("modal-discount");
        // assign with triggering reactivity
        this.currentOrder.items[index].discount = discountedItem.discount;
        this.currentOrder.items[index].subtotal = discountedItem.subtotal;
        this.currentOrder.items[index].tax = discountedItem.tax;
        this.currentOrder.items[index].tax_items = discountedItem.tax_items;
        if (this.employee.role === EMPLOYEE_ROLE.Cashier) this.isVerified = false;
      }

      //count all the essentail values again and then sync
      this.countSubtotal();
      this.sync();
      if (this.currentOrder.discount_orders_raw_value)
        this.countSubtotal();

      this.discount.val="";
    },
    searchProductByName: function (textToSearch) {
      return this.products.filter(product=>{
        if (product.name.toLowerCase().includes(textToSearch.toLowerCase().trim()) && product.id !== -2) {
          return product
        }
        //search for products having multiple barcodes with as comma separated string
        else if(product.barcode){
          if(product.barcode.includes(",")){
            const exsits = product.barcode.split(",").find(barCodes=>barCodes==textToSearch)
            if(exsits)
              return product
          }
          else if(product.barcode === textToSearch){
            return product
          }
        }
      })

    },
    saveDiscountToOrder: async function ({ value, discountType }) {
      /**
       * This function is used to calculate the discount on the whole order
      */
      this.currentOrder.discount_orders_raw_value = value;
      this.currentOrder.discount_orders_raw_type = discountType;
      this.currentOrder.discount_orders = value;
      // setting whole order discount only if the discount value is greater than 0
      if (value === 100 && discountType === 1)
      this.currentOrder.whole_order_discount = true
      else
      this.currentOrder.whole_order_discount = false
      await this.countSubtotal();
      await this.sync();
    },
    displayPaymentDetails: function () {
      return this.isDisplayPaymentDetails = !this.isDisplayPaymentDetails;
    },
    goToCheckout: async function () {
      /**
       * This function syncs the order and redirect to checkout screen
      */
      if (!this.isPaymentTypesAvailable) return;
      this.isLoad = true;
      this.releaseLockedBy = false
      this.isLoad = false;
      await this.sync();
      if (this.$route.params.referenceId) {
        const referencedId = this.$route.params.referenceId
        await this.$router.push({
          name: "OrderCheckout",
          params: { id: (this.currentOrder.id || this.currentOrder.local_id), referenceId: referencedId }
        });
      }
      else {
        await this.$router.push({
          name: "OrderCheckout",
          params: { id: (this.currentOrder.id || this.currentOrder.local_id)}
        });
      }
    },
    verifyAction (employee) {
      if (!employee) {
        return this.isVerified = false;
      }
      this.isVerified = true;

      switch (this.lockedAction) {
        case "item-discount":
          this.saveDiscountToItem();
          break;
        case "remove-item":
          this.removeItemFromOrderProcessing(this.lockedItemId);
          break;
        case "remove-order":
          this.cancelOrderProcessing();
          break;
        case "item-qty":
          this.saveNewQuantity();
          break;
      }
      this.isVerified = false;

      return this.$bvModal.hide("enter-pin-modal");
    },
    checkForCompletedOrder(orderID=null){
      let orderKey = null
      if(orderID){
        orderKey = orderID.toString().includes("_") ? orderID
        : parseInt(orderID);

       const orderInit =  {...this.$store.getters["orders/getOrderByIdOrLocalId"](orderKey)}
       if(orderInit.status === ORDER_STATUS.COMPLETED){
        this.$bvModal.show('complete-order-error')
        return true
       }


      }
      return false

    },
    async errorModalCallBack(){
      this.resetCurrentOrder()
      if (this.terminalMode === "QUICK_SERVICE") {
        // await this.emptyOrder()
        return await this.$router.push({
					name: "Order",
					params: {
						tableID: null,
						id: "new"
					}
				});
			} else if (!this.currentOrder.table) {
				return await router.push({ name: "Tables" });
			}
			const table = this.$store.getters.getTableWithOrders(this.currentOrder.table);
			if (table && table.tableOrders && table.tableOrders.length === 0) {
				await router.push({ name: "Tables" });
			} else if (this.table && this.table.tableOrders) {
				await router.push({ name: "TableOrders", params: { id: this.currentOrder.table } });
			} else if (this.isKioskMode) {
				await router.push({ name: "KioskHome" });
			}
    },
    resetCurrentOrder() {
      this.currentOrder = {
        customer: null,
        localID: 0,
        subtotal: 0,
        items: [],
        table: null,
        tax_total: 0,
        discount_total: 0,
        order_discount: 0,
        status: 1,
        open_date: null,
        number: "0",
        discount_orders: 0,
        payments: [],
        receipt_kitchen_printed: false,
        description: "",
        dining_option_type: null,
        cash_discount: false,
        discount_orders_raw_value: null,
        discount_orders_raw_type: null,
        isActive:false //isActive variable to determine if current order is open the pos window
      }
    },

    async saveGiftCard(event){
      // await this.init();
      let selectedGiftCards = []
      const giftCard = this.products.find(product => product.id === -2)
      if ( giftCard ){
        giftCard.price = event.gift_card_new_credit
        await this.addItemToOrder(giftCard)
      }
      selectedGiftCards = [...this.selectedGiftCards]

      selectedGiftCards.push({
            "gift_card_number": this.activeGiftCard.id,
            "amount_paid": event.gift_card_new_credit,
            "transaction_balance": parseFloat((this.activeGiftCard.credit + event.gift_card_new_credit).toFixed(2)),
            "transaction_type": GIFT_CARD_TRANSACTION_TYPE.CREDIT,
            "terminal": this.terminal.id,
            "order": this.currentOrder.id,
            "is_applied": true,
            "local_id": this.currentOrder.local_id,
            "order_item_local_id": this.currentOrder.items[this.currentOrder.items.length-1].local_id,
            "card_number": this.activeGiftCard.gift_card_number
      })

      this.setSelectedGiftCards(selectedGiftCards)

    },
  }
};
</script>

<style lang="scss">

.product-price {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 1rem;
}

.list-complete-item {
  transition: none;
}

.list-complete-enter, .list-complete-leave-to {
  opacity: 0;
  /*transform: translateY(30px);*/
}

.list-complete-leave-active {
  position: absolute;
}

.popup-price {

  .modal-dialog {
    max-width: 440px;
  }

  .modal-body {
    padding: 30px 42px;

    @media (max-width: 767.98px) {
      padding: 25px 12px;
    }
  }

  .modal-header {
    background: #FAFAFA;
    align-items: center;
    position: relative;
    justify-content: center;
    height: 72px;

    .btn {
      min-width: 84px;
      position: absolute;
      left: 16px;
      top: 50%;
      margin-top: -20px;

      @media (max-width: 767.98px) {
        left: .25rem;
      }
    }

    h5 {
      margin: 0;
      text-align: center;
    }
  }

  .modal-footer {

    border: none;
    position: absolute;
    width: 130px;
    text-align: center;
    top: 0;
    bottom: 0;
    background: #EBEBEB;
    border-radius: 0 10px 10px 0;
    right: -130px;
    border-left: 3px solid #2A7BFF;
    padding: 0;
    align-items: flex-start;
    overflow: hidden;
    height: 100%;

    .w-100 {
      height: 100%;
    }

    h5 {
      font-size: 16px;
      line-height: 19px;
      color: rgba(74, 74, 74, 0.717108);
      background: #FAFAFA;
      height: 71px;
      line-height: 71px;
      margin: 0;
      width: 100%;
    }

    ul {
      overflow-y: auto;
      height: calc(100% - 71px);

      li {
        padding: 1px 0;
      }

      a {
        display: block;
        height: 72px;
        width: 100%;
        font-size: 15px;
        line-height: 21px;
        color: #1E1E1E;
        text-decoration: none;
        background: #fff;
        transition: all .2s ease-in-out;
        padding: 10px;

        &.active,
        &:hover {
          background: #2889FA;
          color: #fff;
        }

        span {
          display: block;
          position: relative;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }

  &-sidebar {
    .modal-content {
      border-radius: 10px 0px 0px 10px;
      position: initial;
    }

    .modal-header {
      border-radius: 10px 0px 0px 0rem;
    }

    .modal-body {
      border-radius: 0rem 0px 0px 10px;
    }
  }
}


.modifiers-sidebar-backdrop {
  background: #212121;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  cursor: pointer;
  opacity: .5;
  z-index: 10;

  /*transition: all 0s;*/
}

.modifiers-sidebar-backdrop[aria-pressed="false"] {
  opacity: 0;
  z-index: -1;
}


.slide-enter-active,
.slide-leave-active {
  left: 0;
  transition: transform 0s;
}

.slide-enter,
.slide-leave-to {
  left: 100%;
  transition: all 0s;
}


.modifiers-sidebar-panel {
  position: absolute;
  z-index: 100;
  top: 0;
  background: #eeeeee;
  left: 0;
  width: 100%;
  bottom: 0;
}

.btn-disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.4;
}

.dropdown-menu {
  margin-top: 45px;
}


@media (max-width: 767.98px) {
  .list-complete-item {
    transition: none !important;
  }
}

.bounce-enter-active {
  animation: bounce-in .5s;
}

.bounce-leave-active {
  animation: bounce-in .5s reverse;
}

.navbar-search-res {
  overflow: auto;
  max-height: 50vh;

  li {
    height: auto;
  }
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
    color: #fff;
    background: red;
  }
  100% {
    transform: scale(1);
  }
}

.sent_to_kitchen .align-items-center {
  font-style: italic;
  color: #888888;
}

.spiner-overbutton {
  position: absolute;
  top: 19px;
  z-index: 10;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}
.hidden {
  display:none !important;
}
</style>
