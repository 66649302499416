<template>
  <div>
    <div class="pin-keyboard text-center my-0">
      <ul class="pin-keyboard-list">
        <li>
          <span
            class="pin-key"
            @click="typeNum(1)"
          >1</span>
        </li>
        <li>
          <span
            class="pin-key"
            @click="typeNum(2)"
          >2</span>
        </li>
        <li>
          <span
            class="pin-key"
            @click="typeNum(3)"
          >3</span>
        </li>

        <li>
          <span
            class="pin-key"
            @click="typeNum(4)"
          >4</span>
        </li>
        <li>
          <span
            class="pin-key"
            @click="typeNum(5)"
          >5</span>
        </li>
        <li>
          <span
            class="pin-key"
            @click="typeNum(6)"
          >6</span>
        </li>

        <li>
          <span
            class="pin-key"
            @click="typeNum(7)"
          >7</span>
        </li>
        <li>
          <span
            class="pin-key"
            @click="typeNum(8)"
          >8</span>
        </li>
        <li>
          <span
            class="pin-key"
            @click="typeNum(9)"
          >9</span>
        </li>

        <li>
          <span
            class="pin-key pin-key_call"
            @click="typeNum('C')"
          >C</span>
        </li>
        <li>
          <span
            class="pin-key"
            @click="typeNum(0)"
          >0</span>
        </li>
        <li>
          <span
            class="pin-key bg-primary"
            @click="clickSave"
          >
            <svg class="icon icon-icon-check">
              <use xlink:href="#icon-icon-check" />
            </svg>
          </span>
        </li>
        <li>
          <span
            class="pin-key"
            @click="typeNum('-') "
          >
            -
          </span>
        </li>
        <li v-if="enableDot">
          <span
            class="pin-key"
            style="opacity: 0"
          />
        </li>
        <li
          v-if="enableDot"
        >
          <span
            class="pin-key"
            @click="typeNum('.')"
          >.</span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "NumKeyboard",
  props: {
    initial: {
      default: 1,
      type: Number
    },
    enableDot: {
      default: false,
      type: Boolean
    },
    config: {
      default: () => ({}),
      type: Object
    }
  },
  data () {
    return {};
  },
  beforeMount () {
    window.addEventListener("keydown", this.pressKey);
  },
  beforeDestroy () {
    window.removeEventListener("keydown", this.pressKey);
  },
  methods: {
    typeNum (symbolTyped) {
      this.$emit("onTypeNum", symbolTyped);
    },
    clickSave () {
      this.$emit("save");
    },
    pressKey (event) {
      let num = null;
      if (event.keyCode >= 48 && event.keyCode <= 57) {
        num = event.keyCode - 48;
      } else if (event.keyCode === 190) {
         num = ".";
      } else if (event.keyCode === 8) {
        num = "C";
      }
      num !== null && this.typeNum(num);
    }
  }
};
</script>