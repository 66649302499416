<template>
  <b-navbar-nav>
    <b-nav-form
      class="navbar-search"
      autocomplete="off"
    >
      <label for="navbar-search-control">
        <svg class="icon icon-icon-search">
          <use xlink:href="#icon-icon-search" />
        </svg>
      </label>
      <b-form-input
        id="navbar-search-control"
        :value="input"
        :placeholder="$t('searchbar.search-item')"
        autocomplete="false"
        @input="onInputChange"
        @focus="show"
      />

      <b-dropdown
        size="lg"
        variant="link"
        toggle-class="text-decoration-none"
        no-caret
      >
        <template v-slot:button-content>
          <svg class="icon icon-icon-code">
            <use xlink:href="#icon-icon-code" />
          </svg>
        </template>
        <b-dropdown-text>
          <div class="pin-keyboard text-center my-0">
            <ul class="pin-keyboard-list">
              <li>
                <span
                  class="pin-key"
                  @click="typeNum(1)"
                >1</span>
              </li>
              <li>
                <span
                  class="pin-key"
                  @click="typeNum(2)"
                >2</span>
              </li>
              <li>
                <span
                  class="pin-key"
                  @click="typeNum(3)"
                >3</span>
              </li>

              <li>
                <span
                  class="pin-key"
                  @click="typeNum(4)"
                >4</span>
              </li>
              <li>
                <span
                  class="pin-key"
                  @click="typeNum(5)"
                >5</span>
              </li>
              <li>
                <span
                  class="pin-key"
                  @click="typeNum(6)"
                >6</span>
              </li>

              <li>
                <span
                  class="pin-key"
                  @click="typeNum(7)"
                >7</span>
              </li>
              <li>
                <span
                  class="pin-key"
                  @click="typeNum(8)"
                >8</span>
              </li>
              <li>
                <span
                  class="pin-key"
                  @click="typeNum(9)"
                >9</span>
              </li>

              <li><span class="pin-key pin-key_call">C</span></li>
              <li><span class="pin-key">0</span></li>
              <li>
                <span class="pin-key bg-primary">
                  <svg class="icon icon-icon-check">
                    <use xlink:href="#icon-icon-check" />
                  </svg>
                </span>
              </li>
            </ul>
          </div>
        </b-dropdown-text>
      </b-dropdown>
    </b-nav-form>

    <SimpleKeyboard
      v-if="visible"
      :input="input"
      @onChange="onChange"
      @onKeyPress="onKeyPress"
    />

    <div
      v-if="visible"
      class="keyboard-mask"
      @click="hide"
    />
  </b-navbar-nav>
</template>
<script>
export default {
	name: "SearchForm",
	components: {
		SimpleKeyboard: () => import("./keyboards/SimpleKeyboard")
	},
	data () {
		return {
			visible: false,
			layout: "normal",
			input: ""
		};
	},
	methods: {
		show () {
			if (!this.visible)
				this.visible = true;
		},
		hide () {
			this.visible = false;
		},
		onChange (input) {
			this.input = input;
		},
		onKeyPress () {},
		onInputChange (input) {
			this.input = input.target.value;
		},
		sendToParent: function () {
			this.$emit("updated", parseFloat(this.numString) / 100);
		},
		typeNum: function (num) {
			if (this.numString === "0") {
				this.numString = "";
			}
			if (this.numString.length < 8) {
				this.numString = this.numString.concat(num.toString());
			}
			this.sendToParent();
		},
		resetNum: function () {
			this.numString = "0";
			this.sendToParent();
		},
		removeLast: function () {
			if (this.numString.length > 1) {
				this.numString = this.numString.slice(0, -1);
			} else if (this.numString !== "0") {
				this.numString = "0";
			}
			this.sendToParent();
		}
	}
};
</script>
<style lang="scss">

</style>
