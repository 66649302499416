<template>
  <div id="app">
    <router-view />
    <!-- <div 
      v-if="secondaryTab" 
      id="app-notallowed" 
      class="kiosk"
    > -->
    <!-- <div class="row h-100 align-content-center">
        <div class="col-12">
          <h3>You can only open one tab Biyo at a time</h3>
          <h4>Having multiple Biyo tabs open at the same time can cause errors in sales</h4>
        </div>
      </div> -->
    <!-- </div> -->

    <SvgSprite />

    <b-modal
      id="sw-update-modal"
      hide-footer
      hide-header
      no-close-on-backdrop
      modal-class="success-popup"
    >
      <div class="text-center modal-complete">
        <svg class="icon icon-icon-success-frame">
          <use xlink:href="#icon-icon-success-frame" />
        </svg>

        <h2>
          {{ $t("sw-modal.new-version") }}
        </h2>

        <span>{{ $t("sw-modal.refresh-page") }}</span>

        <div class="d-flex justify-content-center align-items-center">
          <b-button 
            variant="primary" 
            @click="updateSW"
          >
            Update
          </b-button>
          <b-button 
            variant="outline-primary" 
            class="ml-2" 
            @click="closeUpdateSW"
          >
            close
          </b-button>
        </div>
      </div>
    </b-modal>
    <!-- <b-modal
      id="app-is-offline"
      hide-footer
      hide-header
      no-close-on-backdrop
      modal-class="success-popup"
    >
    <div>
      <span
        @click="$bvModal.hide('app-is-offline')"
        class="close-button"
        aria-label="Close"
      >
        &times;
      </span>
    </div>
    <div class="text-center modal-complete">
      <svg class="icon icon-icon-success-frame">
        <use xlink:href="#icon-icon-success-frame" />
      </svg>

      <h2>
        {{ $t("offline-modal.is-offline") }}
      </h2>

      <div class="d-flex justify-content-center align-items-center">
        <b-button 
          variant="primary" 
          @click="checkConnection"
        >
          {{ $t("offline-modal.try-again") }}
        </b-button>
      </div>
    </div>
  </b-modal> -->
    <b-modal
      id="dulpicate-tab-modal"
      v-model="secondaryTab"
      hide-header
      no-close-on-backdrop
      centered
      modal-class="duplicate-tab-popup"
      @ok="useHere()"
    >
      <div>
        <span>Biyo is open in another window. Click "Use Here" to use Biyo in this window</span>
      </div>
      <template #modal-footer="{ ok }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button
          size="sm"
          variant="primary"
          @click="ok"
        >
          Use Here
        </b-button>
      </template>
    </b-modal>
    <NewOrderRecevieModal 
      :modal="newOrderReceiveModal"
      @confirm-action="confirmAction"
    />
  </div>
</template>

<script>
import "@/assets/sass/style.scss";
import {
  setCurrentTab
} from './helpers/activeTabMonitor';
import SvgSprite from "@/components/SvgSprite";
import { mapGetters } from "vuex"
import customerDisplayMixin from "@/mixins/customerDisplayMixin";
import pusherMixin from "@/mixins/pusherMixin";
import NewOrderRecevieModal from "./modals/Order/NewOrderRecevieModal.vue";

window.rsvp = require("rsvp");
window.RSVP = require("rsvp");
window.sha = require("sha.js");

export default {
  name: "App",
  components: {
    SvgSprite,
    NewOrderRecevieModal
  },
  mixins:[
    customerDisplayMixin,
    pusherMixin
  ],
  data () {
    return {
      refreshing: false,
      secondaryTab: false,
      channel: null,
      pusher: null,
      newOrderReceiveModal: {
        title: 'New Order Received', 
        text: 'Do you want to go to the Online Order Page?', 
        cancelActionText: 'Later', 
        confirmActionText: 'Yes',
      },
    };
  },
  computed: {
    ...mapGetters({
      baseUrl: "config/baseUrl",
      store: "config/store",
    }),
    isOnline () {
      return this.$store.getters.connection;
    }
  },
  watch: {
    isOnline (online) {
      return online ? this.$bvModal.hide("app-is-offline") : this.$bvModal.show("app-is-offline");
    }
  },
  created() {
    // created channel to communicate between two chrome tabs
    this.channel = new BroadcastChannel('my-channel');
    this.channel.onmessage = this.handleChannelMessage;   
    if (this.$workbox) {
      this.$workbox.addEventListener("waiting", () => {
        this.showUpdateUI = true;
      });
    }
    setCurrentTab(() => {
      // post message when second tab is detected
      this.channel.postMessage('second tab')
   });

  },
  async mounted() {
    // pusher initialization
    if (navigator.onLine && this.baseUrl) {
        this.subscribe();
    }
    const isWebRTC = localStorage.getItem('isWebRTC');
    if (isWebRTC) {
      this.getConnectionKey();
    }
  },
  beforeDestroy() {
    if (this.pusher)
    this.pusher.disconnect();
  },
  methods: {
    updateSW() {
      window.location.reload();
    },
    closeUpdateSW () {
      return this.$bvModal.hide("sw-update-modal");
    },
    checkConnection () {
      this.$store.dispatch("setConnection", navigator.onLine);
    },
    handleChannelMessage(event) {
      // hault the previous tab
      if (event.data === 'second tab') {
        this.secondaryTab = true
      }
    },
    useHere() { 
      this.channel.postMessage('second tab')
      // location.reload();
    },
    confirmAction() {
      this.$bvModal.hide('new-order-received')
      this.$router.push('/online-orders')
    }
  }
};
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: #eee;
  height: 100%;
  min-height: 100vh;
}

.close-button {
  position: absolute;
  top: 5px;
  right: 20px;
  cursor: pointer;
  font-size: 24px;
  font-weight: bold;
  color: #000;
}

.test-top {
  position: fixed;
  top: 0;
  left: 50%;
  right: 0;
  z-index: 100000;
}
</style>
