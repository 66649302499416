import Vue from "vue";
import Vuex from "vuex";
import { TERMINAL_MODE } from "@/helpers/terminal/mode";
import { orderList } from "./orderList";
import { errorModal } from "./modal/error";
import createPersistedState from "vuex-persistedstate";
import router from "../router";
import { config } from "./modules/config";
import orders from "./modules/orders";
import shifts from "./modules/shifts";
import DashboardService from "../services/dashboard-service";
import { ORDER_STATUS } from "@/helpers/order/status";
import { backgroundSync } from "@/helpers/sync";
import customers from "./modules/customers";
import { itemNote } from "./modal/itemNote";
import { itemPriceAdjust } from "./modal/itemPriceAdjust";
import { defaultState } from "@/store/defaultState";
import qtyModal from "@/store/modules/qtyModal";
import customerDisplay from "@/store/modules/customerDisplay";
import discount from "@/store/modules/discount"
import indexDbService from "@/helpers/dbHelper"
import { surcharge } from "./modal/surcharge";
import * as Sentry from "@sentry/vue";
import {giftVoucher} from "@/store/modal/giftVoucher"


Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState({
    key: "data",
    paths: [
      'errorModal',
      'itemNote',
      'itemPriceAdjust',
      'qtyModal',
      'customerDisplay',
      'discount',
      'config.tableUpdatedLocally',
      'config.baseUrl',
      'config.token',
      'config.terminal',
      'orders.pagination',
      'orders.isLoad',
      'orders.emptyCurrentOrder',
      'orders.refundedOrderDiscountTotalDetails',
      'orders.refundOrderId',
      'orders.refundItems',
      'orders.refundedOrderTableId',
      'shifts.updating_shift',
      'customers.updating_instance',
      'orderList.isLoad',
      'orderList.lastStatus',
      'orderList.page',
      'isSyncInProgress',
      'fullscreen',
      'count',
      'blocked',
      'unlocked',
      'loadingPercent',
      'submittedNotes',
      'syncProgress',
      'savingProcess',
      'terminal',
      'isOnline',
      'activeTableIndex',
      'ordersToSave',
      'employee',
      'orderChaneel',
      'syncQueue',
      'tablesListMode',
      'terminal',
      'shift',
      'config.store',
      'config.sections',
      'config.employees',
      'surcharge'
    ]
  })],
  modules: {
    orderList,
    errorModal,
    itemNote,
    itemPriceAdjust,
    orders,
    shifts,
    customers,
    config,
    qtyModal,
    customerDisplay,
    discount,
    surcharge,
    giftVoucher
  },
  state: defaultState,
  getters: {
    tablesListMode: state => state.tablesListMode,
    shift: state => state.shift,
    isUnlocked: state => state.unlocked,
    isSyncInProgress: state => state.isSyncInProgress,
    syncQueue: state => state.syncQueue,
    employee: state => state.employee,
    isSavingProcess: state => state.ordersToSave !== 0,
    syncProgress: state => state.syncProgress,
    terminal: state => state.terminal,
    terminalMode: state => state.config.terminal ? TERMINAL_MODE[state.config.terminal.mode] : null,
    connection: state => state.isOnline,
    isShiftOpen: state => !!(state.shift && state.shift.local_id && !state.shift.shift_close_date),
    activeTableIndex: state => state.activeTableIndex,
    dataChannel: state => state.orderChannel,
    sectionsWithTableOrders: (state,rootGetters) => {
      const clonedSections = JSON.parse(JSON.stringify(state.config.sections));
      clonedSections.map((section, sIndex) => {
        section.tables.map((table, tIndex) => {
          clonedSections[sIndex].tables[tIndex]["tableOrders"] = [...rootGetters['orders/getAllOrders'].filter(order => ((order.table === table.id) && (order.status === ORDER_STATUS.OPEN)))]
        });
      });
      return clonedSections;
    },
    getTableWithOrders: (state) => (id) => {
      const table = state.config.tables.find((table) => table.id === id);
      table["tableOrders"] = state.orders.orders.filter(order => ((order.table === table.id) && (order.status === ORDER_STATUS.OPEN)));
      return table;
    },
    callOrderListOnTableInitall: (state)=>state.callOrderListOnTableInitall
  },
  mutations: {
    setCurrentShift (state, payload) {
      state.shift = payload;
    },
    toggleTableListMode (state) {
      state.tablesListMode = !state.tablesListMode;
    },
    toggleFullscreen (state) {
      state.fullscreen = !state.fullscreen;
    },
    SET_BLOCKED: (state, newValue) => {
      state.blocked = newValue;
    },
    unlock (state) {
      state.unlocked = true;
    },
    lock (state) {
      state.unlocked = false;
      localStorage.removeItem('isWebRTC');
      router.push({ name: "Pin" });
    },
    setLoadingPercent (state, newPercent) {
      state.loadingPercent = newPercent;
    },
    setSyncProgress (state, progress) {
      state.syncProgress = progress;
    },
    SET_TERMINAL: (state, value) => state.terminal = value,
    SET_CONNECTION: (state, value) => state.isOnline = value,
    SET_SHIFT: (state, value) => {
      state.shift = value,
      indexDbService.saveToDb('shift',state.shift).then()
    },
    SET_CHANNEL: (state, value) => state.orderChannel = value,
    START_SAVING (state) {
      state.savingProcess = true;
      state.ordersToSave++;
    },
    END_SAVING (state) {
      state.savingProcess = false;
      state.ordersToSave--;
    },
    SET_TABLE_INDEX: (state, value) => state.activeTableIndex = value,
    SET_EMPLOYEE: (state, employee) => state.employee = employee,
    startSync: (state) => state.isSyncInProgress = true,
    endSync: (state) => state.isSyncInProgress = false,
    addSyncToQueue: (state) => {
      state.syncQueue.push({ requested_at: new Date });
    },
    clearQueue: (state) => state.syncQueue = [],
    reset: (state) => Object.assign(state, defaultState),
    updateShiftIdIfMatch: (state, payload) => {
      state.shift = state.shift.local_id === payload.local_id ? { ...state.shift, ...{ id: payload.id } } : state.shift;
    },
    setCallOrderList : (state,payload)=> state.callOrderListOnTableInitall = payload
  },
  actions: {
    sync: async ({ getters, commit }) => {
      // if (getters.isSyncInProgress === false) {
        do {
        commit("clearQueue");
        await backgroundSync();
        } while (getters.syncQueue.length > 0);
      // }
    },
    reset: async ({ commit }) => {
       commit("reset");
    },
    setConnection: ({ commit }, payload) => commit("SET_CONNECTION", payload),
    setTerminal: ({ commit }, payload) => commit("SET_TERMINAL", payload),
    setOrderChannel: ({ commit }, payload) => commit("SET_CHANNEL", payload),
    setTableIndex: ({ commit }, payload) => commit("SET_TABLE_INDEX", payload.activeIndex),
    setEmployee: ({ commit }, payload) => commit("SET_EMPLOYEE", payload.employee),
    fetchCurrentShift: async ({ commit }) => {
      try {
        commit("startSync");
        const retrieved = await DashboardService.getCurrentShift();
        commit("SET_SHIFT", retrieved);
        commit("endSync");
      } catch (e) {
        Sentry.captureException(e);
        commit("endSync");
        console.error(e);
      }
    },
    toggleCallOrderList: async ({ commit }, callOrderList) => {
      commit('setCallOrderList', callOrderList)
    }
    // setDbData: ({ commit }, commitArray) => {
    //   commitArray.forEach(async mutate => {
    //     const data = await indexDbService.getDbData(mutate.key);
    //     const keys = ['store','currency','tax','shift']
    //     if (keys.includes(mutate.key)) {
    //       commit(mutate.commit, data[0], { root: true })
    //     }
    //     else {
    //       commit(mutate.commit, data, { root: true })
    //     }
    //   })
    //  }
  }
});
