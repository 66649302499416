import { fetchWithTimeout } from '../utils';

export const makeDejavooPayment = async (url, amount, referenceId, invoiceNumber, transaction_type, tpn, authkey) => {
  try {
    const headers = {
      'Content-Type': 'application/json'
    };

    const payload = {
      body: {
        Amount: amount.toString(),
        TipAmount: "0.00",
        ExternalReceipt: "",
        PaymentType: transaction_type,
        ReferenceId: referenceId,
        PrintReceipt: "No",
        GetReceipt: "No",
        MerchantNumber: "",
        InvoiceNumber: invoiceNumber,
        CaptureSignature: false,
        GetExtendedData: true,
        CallbackInfo: {
          Url: ""
        },
        Tpn: tpn,
        Authkey: authkey,
        SPInProxyTimeout: "60",
        CustomFields: {}
      }
    };

    const response = await fetchWithTimeout(60000, fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(payload)
    }));

    const text = await response.text();

    try {
      const data = text ? JSON.parse(text) : {};
      
      if (data.Amounts) {
        return data;
      } else {
        console.log('Waiting for payment completion...');
        return data;
      }
    } catch (parseError) {
      console.error('Error parsing response:', parseError);
      throw new Error(`Error processing payment response. Status: ${response.status}`);
    }
  } catch (error) {
    console.error('Error in Dejavoo payment:', error);
    throw error;
  }
};

export const makeDejavooRefund = async (url, amount, referenceId, invoiceNumber, tpn, authkey) => {
  try {
    const headers = new Headers({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST, OPTIONS',
      'Access-Control-Allow-Headers': 'Content-Type'
    });

    const payload = {
      Amount: amount,
      TipAmount: null,
      ExternalReceipt: "",
      PaymentType: "Credit",
      ReferenceId: referenceId,
      PrintReceipt: "No",
      GetReceipt: "No",
      MerchantNumber: null,
      InvoiceNumber: invoiceNumber,
      CaptureSignature: false,
      GetExtendedData: true,
      CallbackInfo: {
        Url: ""
      },
      Tpn: tpn,
      Authkey: authkey,
      SPInProxyTimeout: null,
      CustomFields: {},
      TransactionType: "Refund"
    };

    const response = await fetchWithTimeout(60000, fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(payload)
    }));

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error in Dejavoo refund:', error);
    throw error;
  }
};
