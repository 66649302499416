import authHeader from "./auth-header";

export const apiCall = async (url, method = "get", body = {}, extraHeaders = null) => {

  let headers = {
    "Content-Type": "application/json",
    "Accept": "application/json"
  };
  if (extraHeaders) {
    headers = { ...headers, ...extraHeaders };
  }

  const fetchConfig = {
    method: method.toUpperCase(),
    headers: new Headers(headers),
    withCredentials: true
  };

  if (method.toLowerCase() !== "get" && Object.keys(body).length !== 0)
    fetchConfig.body = JSON.stringify(body);

  return await fetch(url, fetchConfig).then(async (response) => {
    switch (response.status) {
      case 200:
        return response.json();
      default:
        throw Error(`Got ${response.status} at ${url}`);
    }
  });
};

export const authApiCall = async (url, method = "get", body = {}, extraHeaders = null) => {

  let headers = {
    "Content-Type": "application/json",
    "Accept": "application/json"
  };
  headers = { ...headers, ...authHeader() };
  if (extraHeaders) {
    headers = { ...headers, ...extraHeaders };
  }

  const fetchConfig = {
    method: method.toUpperCase(),
    headers: new Headers(headers),
    withCredentials: true
  };

  if (method.toLowerCase() !== "get" && Object.keys(body).length !== 0)
    fetchConfig.body = JSON.stringify(body);

  return await fetch(url, fetchConfig).then(async (response) => {
    switch (response.status) {
      case 200:
        return response.json();
      default:
        throw Error(`Got ${response.status} at ${url}`);
    }
  });
};
