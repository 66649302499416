<template>
  <div id="c">
    <div class="pin-keyboard text-center my-0">
      <ul class="pin-keyboard-list">
        <li
          v-for="num in nums"
          :key="num"
        >
          <span
            class="num-key"
            @click="typeNum(num)"
          >{{ num }}</span>
        </li>
        <li>
          <span
            class="num-key pin-key_call"
            @click="resetNum()"
          >C</span>
        </li>
        <li>
          <span
            class="num-key"
            @click="typeNum(0)"
          >0</span>
        </li>
        <li v-if="!withconfirm">
          <span
            class="num-key pin-key_call"
            @click="removeLast()"
          >
            <svg class="icon icon-icon-pin-arrow">
              <use xlink:href="#icon-icon-pin-arrow" />
            </svg>
          </span>
        </li>
        <li v-if="type==2">
          <span
            class="num-key pin-key_call pin-key"
            @click="typeNum('00')"
          >
            00
          </span>
        </li>
        <li v-if="withconfirm">
          <span
            class="num-key pin-key_call pin-key bg-primary"
            @click="sendConfirm()"
          >
            <svg class="icon icon-icon-check">
              <use xlink:href="#icon-icon-check" />
            </svg>
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'SimpleNumKeyboard',
    /* Types
    0 - default
    1 - with confirm
    2 - with 2zeros

     */
    props: {
      'withconfirm': {
        type: Boolean,
        required: false,
        default: false
      },
      'type': {
        type: Number,
        required: false,
        default: 0
      },
      'amount': {
        type: String,
        required:false,
        default: ''
      }
    },
    data () {
      return {
        resultStr: '',
        nums: [...Array(9)].map((_, i) => i + 1)
      };
    },
    watch: {
      amount() {
        this.resultStr = this.amount;
      }
    },
    mounted: function () {
      window.addEventListener('keydown', this.onkey);
      this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
        switch (modalId) {
          case 'enter-pin-modal':
          case 'enter-pin-modal--order-price':
            window.removeEventListener('keydown', this.onkey);
            break;
          case 'gift-voucher-modal':
            window.removeEventListener('keydown', this.onkey);
            break;
        }
      });
      this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
        switch (modalId) {
          case 'enter-pin-modal':
          case 'enter-pin-modal--order-price':
            window.addEventListener('keydown', this.onkey);
            break;
        }
      });
    },
    beforeDestroy: function () {
      window.removeEventListener('keydown', this.onkey);
    },
    methods: {
      onkey (event) {
        if (event.keyCode >= 48 && event.keyCode <= 57) {
          this.typeNum(event.keyCode - 48);
        } else if (event.keyCode === 8) {
          this.removeLast();
        }
      },
      sendToParent: function () {
        this.$emit('updated', this.resultStr);
      },
      sendConfirm: function () {
        this.$emit('confirm', true);
      },
      typeNum: function (num) {
        if (this.resultStr+'' === '0') {
          this.resultStr = '';
        }
        if (this.resultStr.length < 8) {
          this.resultStr = this.resultStr.concat(num.toString());
        }
        this.sendToParent();
      },
      resetNum: function () {
        this.resultStr = '0';
        this.sendToParent();
      },
      removeLast: function () {
        if (this.resultStr.length > 0) {
          this.resultStr = this.resultStr.slice(0, -1).toString();
        }
        this.sendToParent();
      }
    }
  };
</script>
<style lang="scss" scoped>
  .pin-page {
    background: linear-gradient(180deg, #1976D2 0%, #2196F3 100%);
    color: #fff;
    min-height: 100vh;

    h6 {
      font-size: 15px;
      line-height: 18px;
    }

    .pin-block {
      padding-top: 15px;
    }
  }

  .navbar {
    padding: 30px 0 30px;
  }

  .navbar-brand {
    padding: 0;
    margin: 0 auto;
    height: 60px;

    img {
      max-height: 100%;
      height: 100%;
    }
  }
</style>
