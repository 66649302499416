// import { getConfigItem, put, setConfigItem } from '../helpers/dbHelper';
// import { apiCall } from '../helpers/requestHelper';
// import orderMixin from './order/orderMixin';
import { PAYMENT_BEHAVIOR } from '../helpers/order/payment/behavior';

export default {
  // mixins: [orderMixin],
  methods: {
    // async postShift(currentLocalShift) {
    //   // const baseUrl = (await getConfigItem('connection')).url;
    //   // const terminal = await getConfigItem('terminal');
    //   // // const url = baseUrl + '/api/shifts/sync';
    //   // const method = 'POST';
    //   // let body = currentLocalShift;
    //   // try {
    //   //   const data = await apiCall(url, method, terminal.token, body);
    //   //   const currentShift = await getConfigItem('shift');
    //   //   const newShift = currentShift ? Object.assign(data, currentShift) : data;
    //   //   await setConfigItem('shift', newShift);
    //   //   await put('shifts', newShift);
    //   // } catch (e) {
    //   //   body = JSON.stringify(body);
    //   //   await navigator.serviceWorker.ready.then(reg => {
    //   //     reg.active.postMessage({
    //   //       action: 'workbox-queue',
    //   //       request: { url, method, body, token: terminal.token }
    //   //     });
    //   //   });
    //   // }
    // },
    shiftSync: async function () {
      console.log('[shiftmixin] shiftSync');
      // try {
      //   if ('serviceWorker' in navigator && 'SyncManager' in window) {
      //     console.log('serviceWorker in nav');
      //     await navigator.serviceWorker.ready
      //       .then(async reg => {
      //         await reg.sync.register('upsync');
      //         // await reg.sync.register('shift_sync');
      //         console.log('shiftmixin after syncregister');
      //       });
      //   } else {
      //     console.log('else serviceWorker in nav');
      //     await this.postOrder(this.currentOrder);
      //     await this.postShift(await getConfigItem('shift'));
      //   }
      // } catch (e) {
      //   console.error(e);
      // }
    },
    shiftUpdate: async function () {
      console.log('[shiftmixin] shiftSync');
      // try {
      //   if ('serviceWorker' in navigator && 'SyncManager' in window) {
      //     console.log('serviceWorker in nav');
      //     await navigator.serviceWorker.ready
      //       .then(async reg => {
      //         // await reg.sync.register('upsync');
      //         await reg.sync.register('shift_sync');
      //         console.log('shiftmixin after syncregister');
      //       });
      //   } else {
      //     console.log('else serviceWorker in nav');
      //     // await this.postOrder(this.currentOrder);
      //     await this.postShift(await getConfigItem('shift'));
      //   }
      // } catch (e) {
      //   console.error(e);
      // }
    },
    savePaymentToShift: async function (paymentObj, type = 'pay') {
      const currentShift = await this.$store.getters.shift
      if (!currentShift) return await self.$router.push({ name: 'Shift' });
      type === 'pay' ? currentShift.totalTransactions++ : currentShift.totalTransactions--;
      currentShift.shift_amount = parseFloat((currentShift.shift_amount + paymentObj.amount).toFixed(2));
      const pType = this.paymentTypes.find(p => p.name === paymentObj.name);
      currentShift.total_cashtenders = pType.behavior === PAYMENT_BEHAVIOR.ALLOW_CHANGE ? parseFloat((currentShift.total_cashtenders + paymentObj.amount).toFixed(2)) : currentShift.total_cashtenders;
      if (!currentShift.orders) {
        currentShift.orders = [];
      }
      currentShift.orders.push(this.currentOrder.id);
      if (!currentShift.payment_types) {
        currentShift.payment_types = [];
      }
      if (!(currentShift.payment_types.find(p => p.name === paymentObj.name))) {
        currentShift.payment_types.push({
          'name': paymentObj.name,
          'sum': 0
        });
      }
      currentShift.payment_types.find(p => p.name === paymentObj.name).sum += paymentObj.amount;
      this.$store.commit('shifts/save', currentShift)
      // console.log('currentShift before save', currentShift);
      // await setConfigItem('shift', currentShift);
    }
  }
};
