import DashboardService from "../../services/dashboard-service";
import md5 from "md5";
import { TERMINAL_MODE } from "@/helpers/terminal/mode";
import { TAX_OPTION } from "@/helpers/store/tax_options";
import indexDbService from "@/helpers/dbHelper"

export const config = {
  namespaced: true,
  state: {
    baseUrl: null,
    token: null,
    terminal: null,
    store: null,
    tableUpdatedLocally: false,
    store_terminals: [],
    sections: [],
    tables: [],
    products: [],
    categories: [],
    employees: [],
    discounts: [],
    currency: {}
  },
  getters: {
    baseUrl: (state) => {
      return state.baseUrl;
    },
    isKioskMode: (state) => {
      return state.terminal && state.terminal.mode ? TERMINAL_MODE[state.terminal.mode] === "KIOSK_MODE" : false;
    },
    token: (state) => {
      return state.token;
    },
    terminal: (state) => {
      return state.terminal;
    },
    store: (state) => {
      return state.store;
    },
    isTaxInclusive: (state) => {
      return state.store.tax_option === TAX_OPTION.TAX_INCLUSIVE;
    },
    tax: (state) => {
      return state.tax;
    },
    sections: state => {
      return state.sections;
    },
    discounts: state => {
      return state.discounts;
    },
    products: (state) => {
      return state.products.sort(function (a, b) {
        return a.sorting - b.sorting;
      });
    },
    product: (state) => (id) => {
      return state.products.find(p => p.id === id);
    },
    categories: (state) => {
      return state.categories.sort(function (a, b) {
        return a.sorting - b.sorting;
      }).filter(category=>category.id !== -2); // do not show gift card category 
    },
    paymentTypes: (state) => {
      let payment_types = state.terminal.payment_types;
      const is_kiosk = TERMINAL_MODE[state.terminal.mode] === "KIOSK_MODE";
      payment_types = payment_types.filter(pt => is_kiosk ? pt.is_at_kiosk : pt.is_at_pos);
      return payment_types;
    },
    employeeById: (state) => (id) => {
      return state.employees.find((item) => item.id === id);
    },
    getTableById: (state) => (id) => {
      return state.tables.find((t) => t.id === id);
    },
    currency: (state) => {
      return state.currency.symbol || "$";
    },
    storeTerminals: (state) => {
      return state.store_terminals;
    },
    getAllTables: (state) => state.tables,
    enableOnlineOrders: (state) => {
      let setting = undefined
      setting = state.terminal.db_settings.find(setting => setting.name === "online_order")?.value // the value of 'value' is string and not boolean i.e. it is "true" and not true
      // checks for false or undefined will hide online orders while true or null will show online orders
      if(setting === 'true' || setting === null)
        return 'true'
      else
        return 'false'
    },
    isLocalization: (state) => { 
      return state.store && state.store.country === 'US'
    }
  },
  mutations: {
    setBaseUrl (state, baseUrl) {
      state.baseUrl = baseUrl;
      localStorage.setItem('baseUrl',baseUrl)
    },
    setToken (state, token) {
      state.token = token;
      localStorage.setItem('token',state.token)
    },
    setTerminal (state, terminal) {
      state.terminal = terminal;
      localStorage.setItem('terminal',JSON.stringify(state.terminal))
    },
    setStore (state, payload) {
      state.store = payload;
    },
    setTax (state, payload) {
      state.tax = payload;
    },
    setCurrency (state, payload) {
      state.currency = payload;
      indexDbService.saveToDb('currency',state.currency).then()
    },
    setSections (state, payload) {
      state.sections = payload;
      state.sections.forEach(section=>indexDbService.saveToDb('sections',section).then())
    },
    setDiscounts (state, payload) {
      state.discounts = payload;
      state.discounts.forEach(discounts=>indexDbService.saveToDb('discounts',discounts).then())
    },
    async setTables (state, payload) {
      state.tables = payload;
    },
    async setTable (state, payload) {
      const index = state.tables.findIndex((t) => t.id == payload.id);
      state.tables[index] = { ...payload, ...{ updated: true } };
      state.tableUpdatedLocally = true;
      await indexDbService.saveToDb('tables',state.tables[index])
      // update at section
      const sIndex = state.sections.findIndex(s => s.id === payload.section);
      const tIndex = state.sections[sIndex].tables.findIndex(t => t.id === payload.id);
      state.sections[sIndex].tables[tIndex] = payload;
      await indexDbService.saveToDb('sections',state.sections[sIndex].tables[tIndex])
    },
    setTableUpdatedLocally (state, payload) {
      state.tableUpdatedLocally = payload;
    },
    setProducts (state, payload) {
      state.products = payload;
    },
    async setCategories(state,payload) {
      state.categories = payload
    },
    setEmployees(state, payload) {
      state.employees = payload;
    },
    setStoreTerminals (state, payload) {
      state.store_terminals = payload;
    }
  },
  actions: {
    getProductsByCategoryId: ({ state }, payload) => {
      return state.products.filter((item) => item.id == payload);
    },
    getEmployeeByHashedPin: ({ state }, pin) => {
      const hashedPin = md5(pin);
      return state.employees.find((item) => item.uid === hashedPin);
    },
    setAuthConfig: async ({ commit }, authSettings) => {
      commit("setBaseUrl", authSettings.url);
      commit("setToken", authSettings.token);
      commit("setTax", authSettings.taxn);
      await indexDbService.saveToDb('tax',authSettings.taxn)
    },
    resetAuthConfig: async ({ commit }) => {
      commit("setBaseUrl", "");
      commit("setToken", "");
      commit("setTax", "");
      await indexDbService.saveToDb('tax','')
    },
    fetchTerminal: async ({ commit }) => {
      if (window.navigator.onLine) {
        const terminalConfig = (await DashboardService.getTerminalConfig());
        commit("setTerminal", terminalConfig["terminal"]);
        commit("setCurrency", terminalConfig["currency"]);
        commit("setStore", terminalConfig["store"]);
        commit("setTax", terminalConfig["tax"]);
        commit("setStoreTerminals", terminalConfig["store_terminals"]);
        await indexDbService.saveToDb('store', terminalConfig["store"])
        await indexDbService.saveToDb('tax', terminalConfig["tax"])
      }
    },
    fetchSections: async ({ commit }) => {
      if (window.navigator.onLine) {
        const retrieved = (await DashboardService.getSections()).results;
        commit("setSections", retrieved);
      }
    },
    fetchTables: async ({ commit }) => {
      if (window.navigator.onLine) {
        const retrieved = (await DashboardService.getTables()).results;
        await retrieved.forEach(async table => await indexDbService.saveToDb('tables', table))
        commit("setTables", retrieved);
      }
    },
    fetchCategories: async ({ commit }) => {
      if (window.navigator.onLine) {
        const retrieved = (await DashboardService.getCategories()).results;
        await retrieved.forEach(async category => await indexDbService.saveToDb('categories', category))
        const categories = await indexDbService.getDbData('categories')
        await commit("setCategories", categories);
      }
    },
    fetchDiscounts: async ({ commit }) => {
      if (window.navigator.onLine) {
        const retrieved = (await DashboardService.getDiscounts()).results;
        await retrieved.forEach(async discounts => await indexDbService.saveToDb('discounts', discounts))
        commit("setDiscounts", retrieved);
      }
    },
    fetchProducts: async ({ commit }) => {
      if (window.navigator.onLine) {
        let response;
        let retrieved;
        response = await DashboardService.getProducts();
        retrieved = response.results;
        while (response.next) {
          response = await DashboardService.getNextProducts(response.next.split('/api')[1]);
          retrieved = [...retrieved, ...response.results];
        }
        await retrieved.forEach(async product => indexDbService.saveToDb('products', product))
        commit("setProducts", retrieved);
      }
    },
    fetchEmployees: async ({ commit }) => {
      if (window.navigator.onLine) {
        const retrieved = (await DashboardService.getEmployees()).results;
        await retrieved.forEach(async employee => await indexDbService.saveToDb('employee',employee))
        commit("setEmployees", retrieved);
      }
    },
    uploadUpdatedTables: async ({ state, commit }) => {
      commit("setTableUpdatedLocally", false);
      // filter updated
      const updatedTables = state.tables.filter(t => t.updated);

      state.tables.map(function (x) {
        x.updated = false;
        return x;
      });
      if (updatedTables.length) {
        const response = (await DashboardService.postTablePositions(updatedTables));
        if (response && !state.tableUpdatedLocally) {
          commit("setTableUpdatedLocally", false);
        }
      }

    },
    async 'fetchProductsFromDb'({ commit }) {
      const products = await indexDbService.getDbData('products')
      commit("setProducts", products);
    },
    async fetchCategoriesFromDb({ commit }) {
      const categories = await indexDbService.getDbData('categories')
      commit("setCategories", categories);
    },
    async fetchEmployeesFromDb({ commit }) {
      const employees = await indexDbService.getDbData('employee')
      commit("setEmployees", employees);
    },
    async fetchDiscountsFromDb({ commit }) {
        const discounts = await indexDbService.getDbData('discounts')
        commit("setDiscounts", discounts);
    },
    async fetchTablesFromDb({ commit }) {
        const tables = await indexDbService.getDbData('tables')
        commit("setTables", tables);
    },
    async fetchTaxFromDb({ commit }) {
        const tax= await indexDbService.getDbData('tax')
        commit("setTax", tax);
    },
  }
};
