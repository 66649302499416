import Vue from "vue";
import Router from "vue-router";
import routes from "./routes";

Vue.use(Router);
Router.prototype

export default new Router({
  routes
});
