import { fetchWithTimeout } from '../utils';
import * as Sentry from "@sentry/vue";

const xml2js = require('xml2js');
const parser = new xml2js.Parser();

const controller = new AbortController();
const signal = controller.signal;

export const makeNAVoid = async (url, processorPaymentResponse) => {
  let processorPaymentResponseParsed;
  let refundResponse;
  let refundResponseParsed;
  try {
    parser.parseString(processorPaymentResponse, function (err, result) {
      processorPaymentResponseParsed = result['RESPONSE'];
    });

    const headers = new Headers({
      'Content-type': 'text/plain',
      'Host': url.replace('http://', '').replace('https://', '')
    });
    const body = `<DETAIL cust_nbr="${processorPaymentResponseParsed['CUST_NBR']}" merch_nbr="${processorPaymentResponseParsed['MERCH_NBR']}" dba_nbr="${processorPaymentResponseParsed['DBA_NBR']}" terminal_nbr="${processorPaymentResponseParsed['TERMINAL_NBR']}">
<TRAN_TYPE>CCRX</TRAN_TYPE>
<ORIG_AUTH_GUID>${processorPaymentResponseParsed['AUTH_GUID']}</ORIG_AUTH_GUID>
<CARD_ENT_METH>Z</CARD_ENT_METH>
</DETAIL>`;

    await fetchWithTimeout(60000, fetch(url, {
      method: 'post',
      signal: signal,
      mode: 'cors',
      headers,
      body
    })).then(response => response.text())
      .then(data => {
        // self.$bvModal.hide('processing-modal');
        refundResponse = data;
      }).catch(function (error) {
        console.error(error);
        // self.$bvModal.hide('processing-modal');
        // self.showModalError('Payment Error', 'Unsuccessful');

        refundResponse = '';
      });
  } catch (e) {
    console.error('general catch');
    console.error(e);
    Sentry.captureException(e);
    return { status: 'error' };
  }
  // process response
  parser.parseString(refundResponse, function (err, result) {
    refundResponseParsed = result;
  });
  if (refundResponseParsed['RESPONSE'] && refundResponseParsed['RESPONSE']['AUTH_RESP'][0] === '00') {
    console.warn('refundResponse[\'response\']');
    console.warn(refundResponseParsed['RESPONSE']);
    return { status: 'success', response: refundResponse['RESPONSE'] };
  } else {
    return { status: 'error', response: '' };
  }
};

export const makeNARefund = async (url, processorPaymentResponse) => {
  let refundResponse;
  try {
    let processorPaymentResponseParsed;
    parser.parseString(processorPaymentResponse, function (err, result) {
      processorPaymentResponseParsed = result['RESPONSE'];
    });

    const headers = new Headers({
      'Content-type': 'text/plain',
      'Host': url.replace('http://', '').replace('https://', '')
    });
    const body = `<DETAIL cust_nbr="${processorPaymentResponseParsed['CUST_NBR']}" merch_nbr="${processorPaymentResponseParsed['MERCH_NBR']}" dba_nbr="${processorPaymentResponseParsed['DBA_NBR']}" terminal_nbr="${processorPaymentResponseParsed['TERMINAL_NBR']}">
<TRAN_TYPE>CCR9</TRAN_TYPE>
<AMOUNT>${processorPaymentResponseParsed['AUTH_AMOUNT']}</AMOUNT>
<ORIG_AUTH_GUID>${processorPaymentResponseParsed['AUTH_GUID']}</ORIG_AUTH_GUID>
<CARD_ENT_METH>${processorPaymentResponseParsed['CARD_ENT_METH']}</CARD_ENT_METH>
<ORIG_BATCH_ID>${processorPaymentResponseParsed['BATCH_ID']}</ORIG_BATCH_ID>
<ORIG_TRAN_NBR>${processorPaymentResponseParsed['TRAN_NBR']}</ORIG_TRAN_NBR>
</DETAIL>`;

    await fetchWithTimeout(60000, fetch(url, {
      method: 'post',
      signal: signal,
      mode: 'cors',
      headers,
      body
    })).then(response => response.text())
      .then(data => {
        // self.$bvModal.hide('processing-modal');
        refundResponse = data;
      }).catch(function (error) {
        console.error(error);
        // self.$bvModal.hide('processing-modal');
        // self.showModalError('Payment Error', 'Unsuccessful');

        refundResponse = '';
      });
  } catch (e) {
    console.error(e);
    Sentry.captureException(e);
    return { status: 'error' };
  }
  parser.parseString(processorPaymentResponse, function (err, result) {
    refundResponse = result;
  });
  if (refundResponse['response'] && refundResponse['RESPONSE']['AUTH_RESP'] && refundResponse['RESPONSE']['AUTH_RESP'][0] === '00') {
    console.warn('refundResponse[response]');
    console.warn(refundResponse['response']);
    return { status: 'success', response: refundResponse['RESPONSE'] };
  } else {
    return { status: 'error', response: '' };
  }
};
