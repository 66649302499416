import * as Sentry from "@sentry/vue";
export default {
  data(){
    return {
      channel: {
        display_is_open: false,
        order: {
          subtotal: 0,
          grand_total: 0,
          tax_total: 0,
          discount_total: 0,
          order_discount: 0,
          items: [],
          surcharge_value:0
        },
        isSale: false,
        sale: {
          isCash: false,
          transactionAmount: 0.00,
          changeTenderAmount: 0.00
        }
      }

    };
  },
  methods: {
    sendOrderToChannel(order) {
      try {
        if (order.isSale) {
          this.channel.isSale = true
          this.channel.sale = Object.assign(this.channel.sale, order)
        }
        else {
          this.channel.order = Object.assign(this.channel.order, order)
          this.channel.display_is_open = true
        }
        this.sendChannelData()
      } catch (e){
        Sentry.captureException(e);
      }
    },
    setDisplayIsOpen(display_is_open) {
      try {
        this.channel.display_is_open = display_is_open;
        this.sendChannelData()
      } catch (e) {
        Sentry.captureException(e);
      }
    },
    sendChannelData() {
      try {
        const channel = this.$store.getters.dataChannel;
        if (channel !== null) {
          if (channel.readyState === "open") {
            if (this.channel.isSale) { 
              channel.send(JSON.stringify({
                sale: this.channel.sale,
                isSale: this.channel.isSale
              }));
            }
            else {
              channel.send(JSON.stringify({
                order: this.channel.order,
                cd_is_open: this.channel.display_is_open,
                isSale:false
              }));
            }
          }
        }
      } catch (e) {
        Sentry.captureException(e);
      }
    }
  }
};
