import apiServiceCustomerDisplay from '@/core/api/CustomerDisplay'
import * as Sentry from "@sentry/vue";
import { mapGetters, mapActions } from 'vuex'
import cdOrderMixin from './cdOrderMixin';

export default {
  mixins: [cdOrderMixin],
  data: () => ({
    peerConnection: null,
    setRemotePromise: null,
    dataChannel: null,
    configuration: {
      iceServers: [{ urls: 'stun:stun.1.google.com:19302' }]
    },
    key: null,
    ajaxInterval: null,
    inRequest: false,
    intervalId: null,
  }),
  computed: {
    ...mapGetters({
      connectionCode: "customerDisplay/connectionCode",
      connectionState: 'customerDisplay/connectionState',
      terminal: 'config/terminal',
      baseUrl: 'config/baseUrl',
      resend: 'customerDisplay/resend',
      customerDisplayDeviceConnected: "customerDisplay/isConnected",
    }),
  },
  watch: {
    resend: {
      handler(val) {
        if (val) {
          if (this.currentOrder && this.currentOrder.items.length > 0)
            this.sendOrderToChannel(this.currentOrder)
          this.setResendConnection(false)
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions({
      setConnectionCode : "customerDisplay/setConnectionCode",
      setConnectionState : "customerDisplay/setConnectionState",
      sendMessage: "customerDisplay/sendMessage",
      setResendConnection: 'customerDisplay/setResendConnection',
      updateCustomerDisplayConnection: 'customerDisplay/updateCustomerDisplayConnection'
    }),
    
    getConnectionKey() {
      this.peerConnection = this.createConnection(this.lasticecandidate);
      this.createDataChannel(this.peerConnection);
      this.peerConnection.onconnectionstatechange = this.handleConnectionStateChange;
      localStorage.setItem('isWebRTC', true);

      
      this.peerConnection.createOffer().then(
        (offer) => {
          this.peerConnection.setLocalDescription(offer).then(() => console.log('Offer Created!'));
        },
        () => console.log('createOfferFailed')
      );
    },
    
    handleConnectionStateChange() {
      switch (this.peerConnection.connectionState) {
        case "connected":
          this.setConnectionState('Connected');
          break;
        case "disconnected":
        case "failed":
          this.setConnectionState('Reconnecting...');
          this.reconnect();
          break;
        default:
          console.log("Unknown");
          break;
      }
    },
  
    reconnect() {
      if (this.reconnectAttempts < this.maxReconnectAttempts) {
        this.reconnectAttempts++;
        setTimeout(() => {
          this.getConnectionKey();  // Re-initiate the connection
        }, this.reconnectDelay);
      } else {
        console.log('Max reconnect attempts reached. Giving up.');
      }
    },
    createConnection(lasticecandidate) {
      try {
        const peerConnection = new RTCPeerConnection(this.configuration);
        peerConnection.onicecandidate = this.handleicecandidate(lasticecandidate, peerConnection);
        return peerConnection;
      } catch (err) {
        Sentry.captureException(err);
        console.log('error: ' + err);
      }
    },
    createDataChannel(peerConnection) {
      this.dataChannel = peerConnection.createDataChannel('send-order');

      this.dataChannel.onopen = () => {
        this.$emit('channel', this.dataChannel);
        this.$store.dispatch('setOrderChannel', this.dataChannel);
        this.dataChannel.send(JSON.stringify({
          terminalId: this.terminal.id
        }));
        this.setResendConnection(true);
      };
      this.dataChannel.onmessage = (data) => console.log(data);
      this.dataChannel.onclose = () => {
        this.updateCustomerDisplayConnection(false)
        this.setConnectionCode('');
      };
    },
    handleicecandidate(lasticecandidate, peerConnection) {
      return function (event) {
        if (event.candidate != null) {
          console.log('new ice candidate');
        } else {
          console.log('all ice candidates');
          lasticecandidate(peerConnection);
        }
      };
    },
    lasticecandidate(peerConnection) {
      const offer = peerConnection.localDescription;
      this.sendOffer(JSON.stringify(offer));
    },
    async sendOffer(offer) {
      const data = new FormData();
      data.append('message', offer);
      data.append('terminal', this.terminal.id);
      data.append('key', this.terminal.id);

      // Retry logic for API call
      const retryApiCall = (retryLimit) => {
        this.intervalId = setInterval(() => {
          if (retryLimit < 5) {
            apiServiceCustomerDisplay.writeSyncMessage(data)
              .then(response => {
                if (response.data.key) {
                  this.key = response.data.key;
                  this.setConnectionCode(this.terminal.id);
                  this.getAnswer();
                  retryLimit = 5;
                }
              })
              .catch(error => {
                retryLimit++;
                console.log(error);
              });
          } else {
            clearInterval(this.intervalId);
          }
        }, 2000);
      }

      apiServiceCustomerDisplay.writeSyncMessage(data)
        .then(response => {
          if (response.data.key) {
            this.key = response.data.key;
            this.setConnectionCode(this.terminal.id);
            this.getAnswer();
          }
        })
        .catch(error => {
          this.loader.show = false;
          retryApiCall(0);
          console.log(error);
        });
    },
    formatKey() {
      if (this.key.toString().length === 5) {
        let keyArray = this.key.toString().split('')
        keyArray.unshift('0');
        this.key = keyArray.join('');
      } else if (this.key.toString().length === 4) {
        let keyArray = this.key.toString().split('');
        keyArray.unshift('0', '0');
        this.key = keyArray.join('');
      }
    },
    getAnswer() {
      if (this.ajaxInterval !== null || this.inRequest) {
        clearInterval(this.ajaxInterval);
        this.ajaxInterval = null;
      }
      this.ajaxInterval = setInterval(() => {
        clearInterval(this.ajaxInterval)
        this.ajaxInterval = null;
        if (!this.inRequest && !this.ajaxInterval) {
          this.inRequest = true;

          apiServiceCustomerDisplay.getSyncMessage({ key: this.key })
            .then(response => {
              this.inRequest = false;
              if (response.data.active) {
                clearInterval(this.ajaxInterval);
                this.peerConnection.setRemoteDescription(JSON.parse(response.data.answer))
                  .then(
                    () => {
                      console.log('Remote connected!');
                      this.updateCustomerDisplayConnection(true)
                      console.log(JSON.parse(response.data.answer));
                    },
                    (error) => {
                      console.log('Remote connection failed!', error);
                    }
                  );
              }
            }).catch(error => {
              console.log(error);
              clearInterval(this.ajaxInterval);
            });
        }
      }, 2000);
    },
    cancelRequest() {
      clearInterval(this.ajaxInterval);
      this.closeAll();
    },
  },
}