<template>
  <div class="pin-page h-100 text-center">
    <b-container 
      fluid 
      class="h-100"
    >
      <b-modal
        id="modal-1"
        v-model="modalShow"
        :body-bg-variant="bodyBgVariant"
        no-close-on-backdrop
        no-close-on-esc
        hide-header-close
        hide-footer
        centered
        title="Please, enter terminal verification code:"
      >
        <div class="pin-num text-center">
          <ul class="pin-num-list">
            <li><span>{{ getCodeSymbol(0) }}</span></li>
            <li><span>{{ getCodeSymbol(1) }}</span></li>
            <li><span>{{ getCodeSymbol(2) }}</span></li>
            <li><span>{{ getCodeSymbol(3) }}</span></li>
            <li><span>{{ getCodeSymbol(4) }}</span></li>
            <li><span>{{ getCodeSymbol(5) }}</span></li>
          </ul>
          <span 
            v-if="!isValid" 
            class="error"
          >{{ $t('verificationcodeinputpopup.verification-error') }}</span>
        </div>
        <div class="pin-keyboard text-center">
          <ul class="pin-keyboard-list">
            <li>
              <span 
                class="pin-key" 
                @click="typeCode(1)"
              >1</span>
            </li>
            <li>
              <span 
                class="pin-key" 
                @click="typeCode(2)"
              >2</span>
            </li>
            <li>
              <span 
                class="pin-key" 
                @click="typeCode(3)"
              >3</span>
            </li>

            <li>
              <span 
                class="pin-key" 
                @click="typeCode(4)"
              >4</span>
            </li>
            <li>
              <span 
                class="pin-key" 
                @click="typeCode(5)"
              >5</span>
            </li>
            <li>
              <span 
                class="pin-key" 
                @click="typeCode(6)"
              >6</span>
            </li>

            <li>
              <span 
                class="pin-key" 
                @click="typeCode(7)"
              >7</span>
            </li>
            <li>
              <span 
                class="pin-key" 
                @click="typeCode(8)"
              >8</span>
            </li>
            <li>
              <span 
                class="pin-key" 
                @click="typeCode(9)"
              >9</span>
            </li>

            <li>
              <span 
                class="pin-key pin-key_call" 
                @click="clearCode()"
              >C</span>
            </li>

            <li>
              <span 
                class="pin-key" 
                @click="typeCode(0)"
              >0</span>
            </li>
          </ul>
        </div>
      </b-modal>

      <ModalAuthorizationNotify />
      <scale-loader
        v-if="spinner.loading"
        :color="spinner.color"
        :size="spinner.size"
      />
    </b-container>
  </div>
</template>

<script>
import * as config from "../config";
import i18n from "@/plugins/i18n";
import ModalAuthorizationNotify from "@/modals/ModalAuthorizationNotify";
import { setDashboardSettings } from "@/helpers/storageHelper";
import { apiCall } from "@/helpers/requestHelper";
import * as Sentry from "@sentry/vue";
import pusherMixin from "@/mixins/pusherMixin";


export default {
  name: "Auth",
  components: {
    ModalAuthorizationNotify,
    ScaleLoader: () => import("vue-spinner/src/ScaleLoader.vue")
  },
  mixins:[
    pusherMixin
  ],
  data () {
    return {
      code: "",
      modalShow: true,
      bodyBgVariant: "light",
      spinner: {
        loading: true,
        size: "50px",
        color: "#007bff"
      },
      isValid: true
    };
  },
  mounted () {
    setTimeout(() => {
      this.spinner.loading = false;
    }, 200);
  },
  created: async function () {
    window.addEventListener("keydown", this.onKey);
  },
  beforeDestroy: function () {
    window.removeEventListener("keydown", this.onKey);
  },
  methods: {
    onKey (event) {
      if (event.keyCode >= 48 && event.keyCode <= 57) {
        this.typeCode(event.keyCode - 48);
      } else if (event.keyCode === 8) {
        this.clearCode();
      }
    },
    async verifyCode (code) {
      this.isValid = true;
      return await apiCall(
        `${config.authBaseUrl}/verify/`,
        "POST",
        false,
        { "verification_code": code });
    },
    async typeCode (num) {
      this.isValid = true;
      const self = this;
      if (this.code.length < 6) {
        this.spinner.loading = false;
        this.code = this.code.concat(num);
      }
      if (this.code.length === 6) {
        let authConfig;
        this.spinner.loading = true;
        try {
          const response = await this.verifyCode(this.code);
          if (response.domain_name) {
            authConfig = {
              url: response.domain_name,
              token: response.api_token
            };
          } else {
            this.$bvModal.show("modal-auth-code-notify");
          }
          await this.$store.dispatch("config/setAuthConfig", authConfig);
          await this.$store.dispatch("config/fetchTerminal");
        } catch (e) {
          Sentry.captureException(e);
          console.warn("catch", e);
        }
        this.spinner.loading = false;
        if (!authConfig) {
          this.clearCode();
        } else {
          if (authConfig.url) {
            this.clearCode();
            setDashboardSettings(authConfig);

            this.subscribe();
            
            await self.$router.push({ name: "Synchronize" });
          } else {
            this.isValid = false;
            this.clearCode();
          }
        }
        this.spinner.loading = false;
      }

      this.spinner.loading = false;
    },
    getCodeSymbol (position) {
      if (position >= this.code.length) {
        return "";
      } else {
        return this.code[position];
      }
    },
    clearCode () {
      this.code = "";
    },
    changeLocale (locale) {
      i18n.locale = locale;
    }

  }
};
</script>

<style lang="scss" scoped>
.pin-page {
  background: linear-gradient(180deg, #1976D2 0%, #2196F3 100%);
  color: #fff;
  min-height: 100vh;

  h6 {
    font-size: 15px;
    line-height: 18px;
  }

  .pin-block {
    padding-top: 15px;
  }
}

.navbar {
  padding: 30px 0 30px;
}

.navbar-brand {
  padding: 0;
  margin: 0 auto;
  height: 60px;

  img {
    max-height: 100%;
    height: 100%;
  }
}

.error {
  color: red;
}
</style>
